import React, { Component } from 'react';
import { Row, Col, Button, Badge, Calendar, Select, Spin, Card } from 'antd'
import { ClockCircleOutlined } from '@ant-design/icons';
import { getTimeSheets } from "../../services/timeSheet";
import { getEmployees } from '../../services/employee';
import TimeSheetFormModal from './TimeSheetFormModal';
import CalendarNoteFormModal from './CalendarNoteFormModal'
import moment from 'moment';
import { connect } from "react-redux";
import { getCalendarNotes } from '../../services/calendarNote';

const { Option } = Select;

class TimeSheetCalendar extends Component {

    constructor(props) {
        super(props);
        const now = moment(new Date())
        this.state = {
            data: [],
            calendarNoteData: [],
            dataLoading: false,
            formModalVislble: false,
            noteFormModalVisible: false,
            modalEditing: false,
            noteModalEditing: false,
            selectedData: {},
            selectedNoteData: {},
            selectedMonth: now.month(),
            selectedYear: now.year(),
            selectedEmployee: undefined,
            employeeChoices: [],
            selectedDateCell: undefined,
            totalPayment: 0,
            totalWithdraw: 0,
        }

        this.getData = this.getData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.setFormModalVisible = this.setFormModalVisible.bind(this);
        this.reloadAfterCreate = this.reloadAfterCreate.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
        this.getDailyData = this.getDailyData.bind(this);
        this.dateCellRender = this.dateCellRender.bind(this);
        this.renderCalendarHeader = this.renderCalendarHeader.bind(this)
        this.onCalendarDateClicked = this.onCalendarDateClicked.bind(this)
        this.setNoteFormModalVisible = this.setNoteFormModalVisible.bind(this)
    }

    onPageChange(page, pageSize) {
        this.setState({
            page: page,
            pageSize: pageSize
        }, () => {
            this.getData()
        })
    }

    onPageSizeChange(current, size) {
        this.setState({
            page: current,
            pageSize: size
        }, () => {
            this.getData()
        })
    }

    onTableChange(pagination, filters, sorter) {
        const { page, pageSize, sortBy, sortDir } = this.state
        this.setState({
            page: pagination['current'] || page,
            pageSize: pagination['pageSize'] || pageSize,
            sortBy: sorter['columnKey'] || sortBy,
            sortDir: sorter['order'] || sortDir,
        }, () => {
            this.getData()
        })
    };

    onSearch(value) {
        this.setState({
            q: value,
            page: 1
        }, () => {
            this.getData()
        })
    }

    async getData(setDataLoading) {
        this.setState({
            dataLoading: setDataLoading
        }, async () => {
            const { selectedMonth, selectedYear, selectedEmployee, employeeChoices } = this.state
            // Construct date for range filter
            let fromDate = moment({ year: selectedYear, month: selectedMonth, day: 1 });
            let toDate = moment({ year: selectedYear, month: selectedMonth, day: 1 });
            fromDate = fromDate.set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).format("YYYY-MM-DD HH:mm:ss")
            toDate = toDate.add('month', 1).set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).format("YYYY-MM-DD HH:mm:ss")

            // Get timesheets
            let result = await getTimeSheets("", {
                "from_date": fromDate,
                "to_date": toDate,
                "timeSheet.employee": selectedEmployee
            }, 1, 100000000, "sign_in_at", "descend")

            // Get calendar notes
            let calendarNoteResult = await getCalendarNotes("", {
                "from_date": fromDate,
                "to_date": toDate,
                "calendarNote.employee": selectedEmployee
            }, 1, 100000000, "note_at", "descend")
            const calNotes = calendarNoteResult.data.calendar_notes
            const timeSs = result.data.time_sheets
            
            // Calculate total wage
            const employeeWage = parseFloat(employeeChoices.find(x => x.id === selectedEmployee).daily_wage);
            let totalWage = 0
            for (let i = 0; i < timeSs.length; i++) {
                let addWage = 0
                // Calculate only day with sign in & sign out time
                if (timeSs[i].sign_in_at && timeSs[i].sign_out_at){
                    const signInAt = moment(timeSs[i].sign_in_at)
                    const signOutAt = moment(timeSs[i].sign_out_at)
                    const duration = moment.duration(signOutAt.diff(signInAt));
                    const hrsDiff = duration.asHours();
                    
                    // If >= 8 then full day
                    if (hrsDiff >= 8){
                        addWage = employeeWage
                    }
                    // If work hour >= 4 then half day
                    else if (hrsDiff >= 4){
                        addWage = (employeeWage/2.0)
                    }
                }
                totalWage += addWage
            }

            // Calculate total withdraw
            let totalWith = 0
            for (let i = 0; i < calNotes.length; i++) {
                totalWith += parseFloat(calNotes[i].withdraw)
            }
            this.setState({
                data: timeSs,
                dataLoading: false,
                calendarNoteData: calNotes,
                totalWithdraw: totalWith,
                totalPayment: totalWage
            })
        })
    }

    async reloadAfterCreate() {
        this.getData(true)
    }

    setFormModalVisible(val) {
        this.setState({
            formModalVislble: val,
            selectedData: {}
        })
    }

    setNoteFormModalVisible(val) {
        this.setState({
            noteFormModalVisible: val,
            selectedData: {}
        })
    }

    async componentWillMount() {
        await this.getEmployeeChoices()
        // this.getData()
    }

    async getEmployeeChoices() {
        const result = await getEmployees("", { 'employee.is_active': true }, 1, 100000000, "name", "ASC")
        const employee = result.data.employees.map(em => {
            return { 'id': em.id, 'name': em.name, 'daily_wage': em.daily_wage }
        })
        this.setState({
            employeeChoices: employee
        })
    }

    getDailyData(value) {
        // Process data for displaying in cell
        const { data, calendarNoteData } = this.state
        const dataArr = []
        for (let i = 0; i < data.length; i++) {
            const signInAt = moment(data[i].sign_in_at)
            const dateOfMonth = signInAt.date()
            const month = signInAt.month()
            const year = signInAt.year()
            if (value.date() === dateOfMonth && value.month() === month && value.year() === year) {
                dataArr.push({
                    key: `${data[i].id}_in`,
                    type: 'success',
                    content: signInAt.format("HH:mm")
                })
                if (data[i].sign_out_at) {
                    dataArr.push({
                        key: `${data[i].id}_out`,
                        type: 'error',
                        content: moment(data[i].sign_out_at).format("HH:mm")
                    })
                }
            }
        }

        for (let j = 0; j < calendarNoteData.length; j++) {
            const noteAt = moment(calendarNoteData[j].note_at)
            const dateOfMonth = noteAt.date()
            const month = noteAt.month()
            const year = noteAt.year()
            if (value.date() === dateOfMonth && value.month() === month && value.year() === year) {
                if (calendarNoteData[j].description) {
                    dataArr.push({
                        key: `${calendarNoteData[j].id}_description`,
                        type: 'default',
                        content: calendarNoteData[j].description
                    })
                }
                if (parseFloat(calendarNoteData[j].withdraw) > 0) {
                    dataArr.push({
                        key: `${calendarNoteData[j].id}_withdraw`,
                        type: 'warning',
                        content: `เบิก ${parseInt(calendarNoteData[j].withdraw)}`
                    })
                }
            }
        }
        return dataArr
    };

    dateCellRender(value) {
        const listData = this.getDailyData(value);
        return (
            listData.map((item) => (
                <div key={item.key}>
                    <Badge status={item.type} text={item.content} />
                </div>
            ))
        );
    };

    async onCalendarDateClicked(value) {
        const { profile } = this.props
        // Do not allow note changes if role is not admin
        if (profile.profile.role !== "ADMIN") {
            return
        }
        const { calendarNoteData } = this.state
        await this.setState({
            selectedDateCell: value
        })
        let found = false
        let selData = {}
        // Check if there is any note on that day
        for (let i = 0; i < calendarNoteData.length; i++) {
            if (value.format("YYYY-MM-DD") === moment(calendarNoteData[i].note_at).format("YYYY-MM-DD")) {
                selData = {
                    id: calendarNoteData[i].id,
                    withdraw: calendarNoteData[i].withdraw,
                    description: calendarNoteData[i].description
                }
                found = true
                break
            }
        }
        // If found then do edit
        // If not then do create modal
        const modalEdit = found ? true : false
        await this.setState({
            noteFormModalVisible: true,
            selectedNoteData: selData,
            modalEditing: modalEdit
        });
    }

    renderCalendarHeader({ value, type, onChange, onTypeChange }) {
        const start = 0;
        const end = 12;
        const monthOptions = [];
        let current = value.clone();
        const localeData = value.localeData();
        const months = [];
        for (let i = 0; i < 12; i++) {
            current = current.month(i);
            months.push(localeData.monthsShort(current));
        }
        for (let i = start; i < end; i++) {
            monthOptions.push(
                <Select.Option key={i} value={i} className="month-item">
                    {months[i]}
                </Select.Option>,
            );
        }
        const year = value.year();
        const month = value.month();
        const options = [];
        for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
                <Select.Option key={i} value={i} className="year-item">
                    {i}
                </Select.Option>,
            );
        }
        return (
            <div
                style={{
                    padding: 8
                }}
            >
                <Row gutter={8}>
                    <Col span={10} xs={8} sm={8} md={10} lg={10} xl={10} xxl={10} />
                    <Col span={6} xs={8} sm={8} md={6} lg={6} xl={6} xxl={6} >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Select
                                size="large"
                                dropdownMatchSelectWidth={false}
                                className="my-year-select"
                                value={year}
                                onChange={(newYear) => {
                                    this.setState({ selectedYear: newYear }, async () => {
                                        await this.getData(false)
                                        const now = value.clone().year(newYear);
                                        onChange(now);
                                    })
                                }}
                            >
                                {options}
                            </Select>
                            <Select
                                size="large"
                                dropdownMatchSelectWidth={false}
                                value={month}
                                onChange={(newMonth) => {
                                    this.setState({ selectedMonth: newMonth }, async () => {
                                        await this.getData(false)
                                        const now = value.clone().month(newMonth);
                                        onChange(now);
                                    })
                                }}
                            >
                                {monthOptions}
                            </Select>
                        </div>
                    </Col>
                    <Col span={7} xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} />
                    <Col span={1}>
                        <Button
                            style={{ float: 'right' }}
                            size="large"
                            type="primary"
                            icon={<ClockCircleOutlined />}
                            onClick={() => this.setState({ formModalVislble: true })}
                        />
                    </Col>

                </Row>
            </div>
        );
    }

    render() {
        const { profile } = this.props
        const { formModalVislble, selectedData, modalEditing, selectedEmployee, employeeChoices,
            dataLoading, selectedMonth, selectedYear, noteFormModalVisible, selectedNoteData,
            selectedDateCell, totalPayment, totalWithdraw } = this.state

        return (
            <div>
                <Row>
                    <Col span={8} xs={6} sm={6} md={8} lg={8} xl={8} xxl={8} />
                    <Col span={10} xs={12} sm={12} md={10} lg={10} xl={10} xxl={10}>
                        <Select
                            size="large"
                            dropdownMatchSelectWidth={false}
                            value={selectedEmployee}
                            onSelect={(em) => {
                                const now = moment(new Date())
                                this.setState({
                                    selectedEmployee: em,
                                    selectedMonth: now.month(),
                                    selectedDate: now.date()
                                }, () => {
                                    this.getData(true)
                                })
                            }}
                            style={{ width: '100%' }}
                        >
                            {employeeChoices.map(emc =>
                                <Option key={emc.name} value={emc.id}>{emc.name}</Option>
                            )}
                        </Select>
                    </Col>
                </Row>
                <br />
                {selectedEmployee && !dataLoading &&
                    <React.Fragment>
                        {profile.profile.role === "ADMIN" &&
                            <React.Fragment>
                                <Row>
                                    <Col span={6} xs={0} sm={0} md={6} lg={6} xl={6} xxl={6} />
                                    <Col span={14} xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
                                        <Card style={{ width: '100%' }}>
                                            <p>ยอดทั้งหมด: {totalPayment.toFixed(2)}</p>
                                            <p>ยอดเบิก: {totalWithdraw.toFixed(2)}</p>
                                            <p>ยอดที่เหลือ: {(totalPayment - totalWithdraw).toFixed(2)}</p>
                                        </Card>
                                    </Col>
                                </Row>
                                <br />
                            </React.Fragment>
                        }
                        <Row>
                            <Col span={24}>
                                <Calendar
                                    dateCellRender={this.dateCellRender}
                                    headerRender={this.renderCalendarHeader}
                                    defaultValue={moment({ year: selectedYear, month: selectedMonth })}
                                    onSelect={this.onCalendarDateClicked}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>


                }
                {
                    dataLoading &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
                        <Spin tip="กำลังโหลด" size="large" />
                    </div>
                }
                <TimeSheetFormModal
                    visible={formModalVislble}
                    setVisible={this.setFormModalVisible}
                    reloadData={this.getData}
                    reloadAfterCreate={this.reloadAfterCreate}
                    editing={false}
                    data={selectedData}
                    selectedEmployee={selectedEmployee}
                    onRef={ref => (this.formModal = ref)}
                />

                <CalendarNoteFormModal
                    visible={noteFormModalVisible}
                    setVisible={this.setNoteFormModalVisible}
                    reloadData={this.getData}
                    reloadAfterCreate={this.reloadAfterCreate}
                    editing={modalEditing}
                    data={selectedNoteData}
                    selectedEmployee={selectedEmployee}
                    onRef={ref => (this.formModal = ref)}
                    selectedDateCell={selectedDateCell}
                />
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        profile: state.profile
    };
}

export default connect(mapStateToProps)(TimeSheetCalendar);
