import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Table, Row, Col, Card  } from 'antd';
import Button from "antd-button-color";
import { PlusOutlined, MinusOutlined, PlusCircleFilled, MinusCircleFilled, ArrowLeftOutlined } from '@ant-design/icons';
import { getStockHistory, getItem } from "../../../services/item";
import { useParams } from "react-router-dom";
import moment from 'moment';
import StockHistoryFormModal from './StockHistoryFormModal';

class StockHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            data: [],
            page: 1,
            pageSize: 5,
            total: 10,
            sortBy: "created_at",
            sortDir: "descend",
            q: "",
            dataLoading: false,
            formModalVislble: false,
            modalEditing: false,
            stockChangeMode: "ADD"
        }

        this.getData = this.getData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.setFormModalVisible = this.setFormModalVisible.bind(this);
        this.reloadAfterCreate = this.reloadAfterCreate.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
        this.onAddStockClick = this.onAddStockClick.bind(this);
        this.onSubStockClick = this.onSubStockClick.bind(this);
        this.onBackClick = this.onBackClick.bind(this);
    }

    onPageChange(page, pageSize) {
        this.setState({
            page: page,
            pageSize: pageSize
        }, () => {
            this.getData()
        })
    }

    onPageSizeChange(current, size) {
        this.setState({
            page: current,
            pageSize: size
        }, () => {
            this.getData()
        })
    }

    onTableChange(pagination, filters, sorter) {
        const { page, pageSize, sortBy, sortDir } = this.state
        this.setState({
            page: pagination['current'] || page,
            pageSize: pagination['pageSize'] || pageSize,
            sortBy: sorter['columnKey'] || sortBy,
            sortDir: sorter['order'] || sortDir,
        }, () => {
            this.getData()
        })
    };

    onSearch(value) {
        this.setState({
            q: value,
            page: 1
        }, () => {
            this.getData()
        })
    }

    async getData() {
        this.setState({
            dataLoading: true
        }, async () => {
            const { q, page, pageSize, sortBy, sortDir } = this.state
            const { itemId } = this.props
            let itemRes = await getItem(itemId)
            let result = await getStockHistory(itemId, q, {}, page, pageSize, sortBy, sortDir)
            this.setState({
                data: result.data.stock_changes,
                total: result.data.total,
                dataLoading: false,
                item: itemRes.data.item
            })
        })
    }

    async reloadAfterCreate() {
        // const { pageSize, page, total } = this.state
        // let totalPages = Math.ceil(total/pageSize)
        // if (total === pageSize && page === totalPages){
        //     totalPages += 1
        // }

        this.setState({
            page: 1
        }, () => {
            this.getData()
        })
    }

    setFormModalVisible(val) {
        this.setState({
            formModalVislble: val
        })
    }

    componentWillMount() {
        this.getData()
    }

    onAddStockClick() {
        this.setState({
            formModalVislble: true,
            stockChangeMode: "ADD"
        })
    }

    onSubStockClick() {
        this.setState({
            formModalVislble: true,
            stockChangeMode: "SUB"
        })
    }

    onBackClick() {
        const { history } = this.props;
        history.push(`/stocks`)
    }

    render() {

        const { data, total, page, pageSize, dataLoading, formModalVislble, item, stockChangeMode } = this.state
        const { itemId } = this.props

        let tableColumns = [
            {

                title: 'คำอธิบาย',
                dataIndex: 'description',
                key: 'description',
                sorter: true
            },
            {
                title: 'ประเภท',
                dataIndex: 'type',
                key: 'type',
                render: (text, row) => (
                    row.type === "ADD" ?
                    <PlusCircleFilled style={{fontSize: 20, color: '#28a745'}}/> : <MinusCircleFilled style={{fontSize: 20, color: '#ff4d4f'}}/>
                ),
                sorter: true
            },
            {
                title: 'จำนวน',
                dataIndex: 'amount',
                key: 'amount',
                sorter: true,
                render: (text, row) => (
                    <span>{text} {item.unit}</span>
                )
            },
            {
                title: 'เวลา',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text, row) => moment(text).format("DD/MM/YYYY HH:mm"),
                sorter: true
            },
            {
                title: 'สร้างโดย',
                dataIndex: 'created_by',
                key: 'created_by',
                render: (text, row) => `${row.created_by.first_name} ${row.created_by.last_name}`,
                sorter: true
            }
        ]

        return (
            <div>
                <Row>
                    <Col span={2}>
                        <Button
                            type="primary"
                            icon={<ArrowLeftOutlined />}
                            onClick={this.onBackClick}
                        >กลับ</Button>
                    </Col>
                    <Col span={10} offset={6}>
                        <Card style={{ width: '100%' }}>
                            <p>ชื่อสินค้า: {item.name}</p>
                            <p>ราคา: {item.price}</p>
                            <p>คำอธิบาย: {item.description}</p>
                            <p>จำนวนในคลัง: {item.current_stock}</p>
                        </Card>
                    </Col>
                </Row>
                <br/>
                <Row>
                    {/* <Col span={10} offset={8}>
                        <Search
                            placeholder="ค้นหาด้วยชื่อ"
                            onSearch={this.onSearch}
                        />
                    </Col> */}
                    <Col span={2} offset={22}>
                        <Button
                            style={{ float: 'right' }}
                            type="danger"
                            icon={<MinusOutlined />}
                            onClick={this.onSubStockClick}
                        />
                        <Button
                            style={{ float: 'right', marginRight: 5 }}
                            type="success"
                            icon={<PlusOutlined />}
                            onClick={this.onAddStockClick}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={24}>
                        <Table
                            rowKey={'id'}
                            columns={tableColumns}
                            dataSource={data}
                            bordered
                            locale={{ filterConfirm: 'OK', filterReset: 'รีเซ็ต', emptyText: 'ไม่พบข้อมูล' }}
                            pagination={{ current: page, pageSize: pageSize, total: total, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '50'] }}
                            loading={dataLoading}
                            onChange={this.onTableChange}
                        />
                    </Col>
                </Row>

                <StockHistoryFormModal
                    visible={formModalVislble}
                    setVisible={this.setFormModalVisible}
                    reloadAfterCreate={this.reloadAfterCreate}
                    mode={stockChangeMode}
                    onRef={ref => (this.formModal = ref)}
                    itemId={itemId}
                />
            </div>
        );
    }
}


function stockHistoryHook(Component) {
    return function WrappedComponent(props) {
      const { id } = useParams();
      return <Component {...props} itemId={id} />;
    }
  }

export default withRouter(stockHistoryHook(StockHistory));