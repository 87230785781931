import axios from "axios";
import { convertSortDir } from "../utils";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const getItems = async (q, filter_params, page, page_size, sort_by, sort_dir) => {
    let params = filter_params
    params['page'] = page
    params['page_size'] = page_size
    params['q'] = q
    params['sort_by'] = sort_by
    params['sort_dir'] = convertSortDir(sort_dir)

    const res = await axios.get(BACKEND_URL+'/items', {
        params,
        withCredentials: true
    });
    return res;
}

export const getItem = async (id) => {
    const res = await axios.get(BACKEND_URL+`/items/${id}`, {
        withCredentials: true
    });
    return res;
}

export const createItem = async (createObj) => {
    createObj  = Object.fromEntries(Object.entries(createObj).filter(([_, v]) => v !== undefined));
    const res = await axios.post(BACKEND_URL+'/items',
    createObj,
    {
        withCredentials: true,
        successMessage: "สินค้าได้ถูกสร้างแล้ว"
    });
    return res;
}

export const updateItem = async (id, updateObj) => {
    const res = await axios.put(BACKEND_URL+`/items/${id}`,
    updateObj,
    {
        withCredentials: true,
        successMessage: "สินค้าได้ถูกแก้ไขแล้ว"
    });
    return res;
}

export const deleteItem = async (id) => {
    const res = await axios.delete(BACKEND_URL+`/items/${id}`,
    {
        withCredentials: true,
        successMessage: "สินค้าได้ถูกลบแล้ว"
    });
    return res;
}

export const getStockHistory = async (itemId, q, filter_params, page, page_size, sort_by, sort_dir) => {
    let params = filter_params
    params['page'] = page
    params['page_size'] = page_size
    params['q'] = q
    params['sort_by'] = sort_by
    params['sort_dir'] = convertSortDir(sort_dir)

    const res = await axios.get(BACKEND_URL+`/items/${itemId}/stock_history`, {
        params,
        withCredentials: true
    });
    return res;
}

export const addStock = async (itemId, createObj) => {
    const res = await axios.post(BACKEND_URL+`/items/${itemId}/add_stock`,
    createObj,
    {
        withCredentials: true,
        successMessage: "คลังได้ถูกเพิ่มแล้ว"
    });
    return res;
}

export const subStock = async (itemId, createObj) => {
    const res = await axios.post(BACKEND_URL+`/items/${itemId}/sub_stock`,
    createObj,
    {
        withCredentials: true,
        successMessage: "คลังได้ถูกลดแล้ว"
    });
    return res;
}

export const recalculateCurrentStock = async (id) => {
    const res = await axios.post(BACKEND_URL+`/items/${id}/recalculate_stock`,
    {},
    {
        withCredentials: true,
        successMessage: "คลังได้ถูกคำนวณใหม่แล้ว"
    });
    return res;
}