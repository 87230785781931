import React, { Component } from 'react';
import { Form, Input, Button, Card, notification } from 'antd';
import './Login.css';
import { login_profile } from '../redux/actions/profile';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.onFinish = this.onFinish.bind(this)
        this.onFinishFailed = this.onFinishFailed.bind(this)
    }

    async onFinish(values) {
        const { history, dispatch } = this.props;
        
        try {
            await dispatch(login_profile(values.username, values.password))
            history.push('/bookings')
        }
        catch (err) {
            console.log(err.response)
            notification["error"]({
                message: `ไม่สามารถลงชื่อเข้าใช้ (${err.response.status})`,
                description: err.response.data.error
            });
        }

        
    };

    onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    };

    render() {

        return (
            <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Card style={{ width: 400 }}>
                    <Form
                        name="basic"
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Form.Item
                            label="ชื่อผู้ใช้"
                            name="username"
                            rules={[{ required: true, message: 'กรุณาใส่ชื่อผู้ใช้' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="รหัสผ่าน"
                            name="password"
                            rules={[{ required: true, message: 'กรุณาใส่รหัสผ่าน' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                ลงชื่อเข้าใช้
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default withRouter(connect(mapStateToProps)(Login));