import React, { Component } from 'react';
import { Table, Input, Row, Col, Button, Modal } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { getTimeSheets } from "../../services/timeSheet";
import TimeSheetFormModal from './TimeSheetFormModal';
import moment from 'moment';
import { connect } from "react-redux";

const { Search } = Input;
const confirm = Modal.confirm;

class TimeSheetList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            pageSize: 5,
            total: 10,
            sortBy: "sign_in_at",
            sortDir: "descend",
            q: "",
            dataLoading: false,
            formModalVislble: false,
            modalEditing: false,
            selectedData: {}
        }

        this.getData = this.getData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        // this.onRowEditClick = this.onRowEditClick.bind(this);
        // this.onRowDeleteClick = this.onRowDeleteClick.bind(this);
        // this.onRowRestoreClick = this.onRowRestoreClick.bind(this);
        this.setFormModalVisible = this.setFormModalVisible.bind(this);
        this.reloadAfterCreate = this.reloadAfterCreate.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
    }

    onPageChange(page, pageSize) {
        this.setState({
            page: page,
            pageSize: pageSize
        }, () => {
            this.getData()
        })
    }

    onPageSizeChange(current, size) {
        this.setState({
            page: current,
            pageSize: size
        }, () => {
            this.getData()
        })
    }

    onTableChange(pagination, filters, sorter) {
        const { page, pageSize, sortBy, sortDir } = this.state
        this.setState({
            page: pagination['current'] || page,
            pageSize: pagination['pageSize'] || pageSize,
            sortBy: sorter['columnKey'] || sortBy,
            sortDir: sorter['order'] || sortDir,
        }, () => {
            this.getData()
        })
    };

    onSearch(value) {
        this.setState({
            q: value,
            page: 1
        }, () => {
            this.getData()
        })
    }

    async getData() {
        this.setState({
            dataLoading: true
        }, async () => {
            const { q, page, pageSize, sortBy, sortDir, selectedDate } = this.state
            let fromDate = moment(selectedDate);
            let toDate = moment(selectedDate);
            // Calculate selected date
            fromDate = fromDate.set('hour', 8).set('minute', 0).set('second', 0).format("YYYY-MM-DD HH:mm:ss")
            toDate = toDate.add(1, 'days').set('hour', 7).set('minute', 59).set('second', 0).format("YYYY-MM-DD HH:mm:ss")
            let result = await getTimeSheets(q, {}, page, pageSize, sortBy, sortDir)
            const timeSheets = result.data.time_sheets.map(ts => {
                ts["employee.name"] = ts.employee.name
                return ts
            })
            console.log(timeSheets)
            this.setState({
                data: timeSheets,
                total: result.data.total,
                dataLoading: false
            })
        })
    }

    async reloadAfterCreate() {
        // const { pageSize, page, total } = this.state
        // let totalPages = Math.ceil(total/pageSize)
        // if (total === pageSize && page === totalPages){
        //     totalPages += 1
        // }

        this.setState({
            page: 1
        }, () => {
            this.getData()
        })
    }

    // onRowEditClick(row) {
    //     const prepareForm = this.formModal.prepareForm
    //     this.setState({
    //             formModalVislble: true,
    //             selectedData: row,
    //             modalEditing: true
    //     }, () => {
    //         setTimeout(function () { prepareForm(); }, 100);
    //     });
    // }

    // onRowDeleteClick(row, lastRow, onPageChange, page, pageSize) {
    //     confirm({
    //         title: `ต้องการลบ ${row.name} หรือไม่?`,
    //         async onOk() {
    //             let response = await deleteTimeSheet(row.id);
    //             if (response.status === 200) {
    //                 if (lastRow && page > 1) {
    //                     page -= 1
    //                 }
    //                 await onPageChange(page, pageSize);
    //             }
    //         },
    //         onCancel() { }
    //     });
    // }

    // onRowRestoreClick(row, onPageChange, page, pageSize) {
    //     confirm({
    //         title: `ต้องการ Activate ${row.name} หรือไม่?`,
    //         async onOk() {
    //             let response = await restoreTimeSheet(row.id);
    //             if (response.status === 200) {
    //                 await onPageChange(page, pageSize);
    //             }
    //         },
    //         onCancel() { }

    //     });
    // }

    setFormModalVisible(val) {
        this.setState({
            formModalVislble: val,
            selectedData: {}
        })
    }

    componentWillMount() {
        this.getData()
    }

    render() {
        const { data, total, page, pageSize, dataLoading, formModalVislble, selectedData, modalEditing, selectedDate } = this.state

        let tableColumns = [
            {

                title: 'ชื่อ',
                dataIndex: 'employee.name',
                key: 'employee.name'
            },
            {

                title: 'เวลาเข้า',
                dataIndex: 'sign_in_at',
                key: 'sign_in_at',
                render: (text, row) => moment(text).format("DD/MM/YYYY HH:mm"),
                sorter: true
            },
            {

                title: 'เวลาออก',
                dataIndex: 'sign_out_at',
                key: 'sign_out_at',
                render: (text, row) => { if (text) {return moment(text).format("DD/MM/YYYY HH:mm")} else {return "-"} },
                sorter: true
            },
            {
                title: 'รายละเอียด',
                dataIndex: 'description',
                key: 'description',
                sorter: true
            },
            // {
            //     title: 'ตัวเลือก',
            //     dataIndex: 'action',
            //     key: 'action',
            //     render: (text, row) => (
            //         <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            //             <Button
            //                 type="default"
            //                 shape="circle"
            //                 size="small"
            //                 icon={<EditOutlined />}
            //                 onClick={() => this.onRowEditClick(row)}
            //                 style={{ marginRight: 5 }}/>
            //             <Button
            //                 type={row.is_active ? 'danger' : 'primary'}
            //                 shape="circle"
            //                 size="small"
            //                 icon={row.is_active ? <DeleteOutlined /> : <RedoOutlined />}
            //                 onClick={
            //                     row.is_active ?
            //                     () => this.onRowDeleteClick(row, data.length === 1, this.getData, page, pageSize)
            //                     : () => this.onRowRestoreClick(row, this.getData, page, pageSize)
            //                 }
            //             />
            //         </div>
            //     )
            // }
        ]

        return (
            <div>
                <Row>
                    <Col span={8} xs={6} sm={6} md={8} lg={8} xl={8} xxl={8}/>
                    <Col span={10} xs={12} sm={12} md={10} lg={10} xl={10} xxl={10}>
                        <Search
                            placeholder="ค้นหาด้วยชื่อ"
                            onSearch={this.onSearch}
                        />
                    </Col>
                    <Col span={1} offset={5}>
                        <Button
                            style={{ float: 'right' }}
                            size="large"
                            type="primary"
                            icon={<ClockCircleOutlined />}
                            onClick={() => this.setState({ formModalVislble: true, modalEditing: false })}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={24}>
                        <Table
                            rowKey={'id'}
                            columns={tableColumns}
                            dataSource={data}
                            bordered
                            locale={{ filterConfirm: 'OK', filterReset: 'รีเซ็ต', emptyText: 'ไม่พบข้อมูล' }}
                            pagination={{ current: page, pageSize: pageSize, total: total, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '50'] }}
                            loading={dataLoading}
                            onChange={this.onTableChange}
                        />
                    </Col>
                </Row>

                <TimeSheetFormModal
                    visible={formModalVislble}
                    setVisible={this.setFormModalVisible}
                    reloadData={this.getData}
                    reloadAfterCreate={this.reloadAfterCreate}
                    editing={modalEditing}
                    data={selectedData}
                    onRef={ref => (this.formModal = ref)}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      profile: state.profile
    };
}
  
export default connect(mapStateToProps)(TimeSheetList);
