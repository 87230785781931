import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Table, Input, Row, Col, Button, Modal, Checkbox, Select } from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  getItems,
  deleteItem,
  recalculateCurrentStock,
} from "../../services/item";
import ItemFormModal from "./ItemFormModal";
import StockHistoryFormModal from "./stockHistory/StockHistoryFormModal";
import { itemTypeChoices, itemTypeMap } from "../../utils/itemType";

const { Option } = Select;
const { Search } = Input;
const confirm = Modal.confirm;

class ItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      pageSize: 5,
      total: 10,
      sortBy: "created_at",
      sortDir: "descend",
      q: "",
      dataLoading: false,
      formModalVislble: false,
      stockFormModalVisible: false,
      modalEditing: false,
      selectedData: {},
      stockChangeMode: "ADD",
    };

    this.getData = this.getData.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onIngredientCheck = this.onIngredientCheck.bind(this);
    this.onRecommendedCheck = this.onRecommendedCheck.bind(this);
    this.onRowEditClick = this.onRowEditClick.bind(this);
    this.onRowDeleteClick = this.onRowDeleteClick.bind(this);
    this.onRowStockHistoryClick = this.onRowStockHistoryClick.bind(this);
    this.setFormModalVisible = this.setFormModalVisible.bind(this);
    this.reloadAfterCreate = this.reloadAfterCreate.bind(this);
    this.onTableChange = this.onTableChange.bind(this);
    this.onRowAddStockClicked = this.onRowAddStockClicked.bind(this);
    this.onRowSubStockClicked = this.onRowSubStockClicked.bind(this);
    this.setStockFormModalVisible = this.setStockFormModalVisible.bind(this);
    this.onRowRecalculateClicked = this.onRowRecalculateClicked.bind(this);
    this.onItemTypeSelected = this.onItemTypeSelected.bind(this);
  }

  onPageChange(page, pageSize) {
    this.setState(
      {
        page: page,
        pageSize: pageSize,
      },
      () => {
        this.getData();
      }
    );
  }

  onPageSizeChange(current, size) {
    this.setState(
      {
        page: current,
        pageSize: size,
      },
      () => {
        this.getData();
      }
    );
  }

  onTableChange(pagination, filters, sorter) {
    const { page, pageSize, sortBy, sortDir } = this.state;
    this.setState(
      {
        page: pagination["current"] || page,
        pageSize: pagination["pageSize"] || pageSize,
        sortBy: sorter["columnKey"] || sortBy,
        sortDir: sorter["order"] || sortDir,
      },
      () => {
        this.getData();
      }
    );
  }

  onSearch(value) {
    this.setState(
      {
        q: value,
        page: 1,
      },
      () => {
        this.getData();
      }
    );
  }

  onItemTypeSelected(e) {
    this.setState(
      {
        selectedItemType: e,
        page: 1,
      },
      () => {
        this.getData();
      }
    );
  }

  onIngredientCheck(e) {
    this.setState(
      {
        isIngredient: e.target.checked,
        page: 1,
      },
      () => {
        this.getData();
      }
    );
  }

  onRecommendedCheck(e) {
    this.setState(
      {
        isRecommended: e.target.checked,
        page: 1,
      },
      () => {
        this.getData();
      }
    );
  }

  async getData() {
    this.setState(
      {
        dataLoading: true,
      },
      async () => {
        const {
          q,
          isIngredient,
          isRecommended,
          selectedItemType,
          page,
          pageSize,
          sortBy,
          sortDir,
        } = this.state;
        const paramObj = {
          "item.is_active": true,
          "item.item_type": selectedItemType
        };
        if (isIngredient) {
          paramObj["item.is_ingredient"] = true;
        }
        if (isRecommended) {
          paramObj["item.is_recommended"] = true;
        }
        let result = await getItems(
          q,
          paramObj,
          page,
          pageSize,
          sortBy,
          sortDir
        );
        this.setState({
          data: result.data.items,
          total: result.data.total,
          dataLoading: false,
        });
      }
    );
  }

  async reloadAfterCreate() {
    // const { pageSize, page, total } = this.state
    // let totalPages = Math.ceil(total/pageSize)
    // if (total === pageSize && page === totalPages){
    //     totalPages += 1
    // }

    this.setState(
      {
        page: 1,
      },
      () => {
        this.getData();
      }
    );
  }

  onRowEditClick(row) {
    this.setState({
      formModalVislble: true,
      selectedData: row,
      modalEditing: true,
    });
  }

  onRowStockHistoryClick(row) {
    // Redirect to item detail page
    const { history } = this.props;
    history.push(`/stock_history/${row.id}`);
  }

  async onRowRecalculateClicked(row) {
    // Recalculate item current stock from the top
    await recalculateCurrentStock(row.id);
    this.getData();
  }

  onRowDeleteClick(row, lastRow, onPageChange, page, pageSize) {
    confirm({
      title: `ต้องการลบ ${row.name} หรือไม่?`,
      async onOk() {
        let response = await deleteItem(row.id);
        if (response.status === 200) {
          if (lastRow && page > 1) {
            page -= 1;
          }
          await onPageChange(page, pageSize);
        }
      },
      onCancel() {},
    });
  }

  setFormModalVisible(val) {
    this.setState({
      formModalVislble: val,
      selectedData: {},
    });
  }

  componentWillMount() {
    this.getData();
  }

  onRowAddStockClicked(row) {
    this.stockFormModal.preFillDescription("ADD");
    this.setState({
      stockFormModalVisible: true,
      selectedData: row,
    });
  }

  onRowSubStockClicked(row) {
    this.stockFormModal.preFillDescription("SUB");
    this.setState({
      stockFormModalVisible: true,
      selectedData: row,
    });
  }

  setStockFormModalVisible(val) {
    this.setState({
      stockFormModalVisible: val,
    });
  }

  render() {
    const {
      data,
      total,
      page,
      pageSize,
      dataLoading,
      formModalVislble,
      modalEditing,
      selectedData,
      stockFormModalVisible,
      stockChangeMode,
    } = this.state;

    let tableColumns = [
      {
        title: "ชื่อ",
        dataIndex: "name",
        key: "name",
        sorter: true,
        render: (text, row) => (
          <a onClick={() => this.onRowStockHistoryClick(row)}>{text}</a>
        ),
      },
      {
        title: "ราคา",
        dataIndex: "price",
        key: "price",
        sorter: true,
      },
      {
        title: "ประเภท",
        dataIndex: "item_type",
        key: "item_type",
        sorter: true,
        render: (text, row) => itemTypeMap[text],
      },
      {
        title: "คำอธิบาย",
        dataIndex: "description",
        key: "description",
        sorter: true,
      },
      {
        title: "สินค้าแนะนำ",
        dataIndex: "is_recommended",
        key: "is_recommended",
        sorter: true,
        render: (text, row) => (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {text ? (
              <CheckCircleOutlined style={{ color: "#52C41A" }} />
            ) : (
              <CloseCircleOutlined style={{ color: "#FF4D4F" }} />
            )}
          </div>
        ),
      },
      {
        title: "วัตถุดิบ",
        dataIndex: "is_ingredient",
        key: "is_ingredient",
        render: (text, row) => (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {text ? (
              <CheckCircleOutlined style={{ color: "#52C41A" }} />
            ) : (
              <CloseCircleOutlined style={{ color: "#FF4D4F" }} />
            )}
          </div>
        ),
      },
      {
        title: "คลัง",
        dataIndex: "current_stock",
        key: "current_stock",
        sorter: true,
        render: (text, row) => (
          <span style={row.current_stock <= 5 ? { color: "red" } : {}}>
            {text} {row.unit}
          </span>
        ),
      },
      {
        title: "คลัง",
        dataIndex: "stock",
        key: "stock",
        render: (text, row) => (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              type="success"
              shape="circle"
              size="small"
              icon={<PlusOutlined />}
              onClick={() => this.onRowAddStockClicked(row)}
              style={{ marginRight: 5 }}
            />
            <Button
              type="danger"
              shape="circle"
              size="small"
              icon={<MinusOutlined />}
              onClick={() => this.onRowSubStockClicked(row)}
              style={{ marginRight: 5 }}
            />
          </div>
        ),
      },
      {
        title: "ตัวเลือก",
        dataIndex: "action",
        key: "action",
        render: (text, row) => (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              size="small"
              icon={<ReloadOutlined />}
              onClick={() => this.onRowRecalculateClicked(row)}
              style={{ marginRight: 5 }}
            />
            <Button
              type="default"
              shape="circle"
              size="small"
              icon={<EditOutlined />}
              onClick={() => this.onRowEditClick(row)}
              style={{ marginRight: 5 }}
            />
            <Button
              type="danger"
              shape="circle"
              size="small"
              icon={<DeleteOutlined />}
              onClick={() =>
                this.onRowDeleteClick(
                  row,
                  data.length === 1,
                  this.onPageChange,
                  page,
                  pageSize
                )
              }
              style={{ marginRight: 5 }}
            />
          </div>
        ),
      },
    ];

    return (
      <div>
        <Row>
          <Col span={8}>
            <Search placeholder="ค้นหาด้วยชื่อ" onSearch={this.onSearch} />
          </Col>
          <Col span={6} offset={1}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="เลือกประเภท"
              optionFilterProp="children"
              onSelect={this.onItemTypeSelected}
              onClear={this.onItemTypeSelected}
              allowClear
            >
              {itemTypeChoices.map((itc) => (
                <Option key={itc.value} value={itc.value}>
                  {itc.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={2} offset={1}>
            <Checkbox onChange={(e) => this.onIngredientCheck(e)}>
              วัตถุดิบ
            </Checkbox>
          </Col>
          <Col span={3}>
            <Checkbox onChange={(e) => this.onRecommendedCheck(e)}>
              สินค้าแนะนำ
            </Checkbox>
          </Col>
          <Col span={1} offset={2}>
            <Button
              style={{ float: "right" }}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                this.setState({ formModalVislble: true, modalEditing: false })
              }
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <Table
              rowKey={"id"}
              columns={tableColumns}
              dataSource={data}
              bordered
              locale={{
                filterConfirm: "OK",
                filterReset: "รีเซ็ต",
                emptyText: "ไม่พบข้อมูล",
              }}
              pagination={{
                current: page,
                pageSize: pageSize,
                total: total,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20", "50"],
              }}
              loading={dataLoading}
              onChange={this.onTableChange}
            />
          </Col>
        </Row>

        <ItemFormModal
          visible={formModalVislble}
          setVisible={this.setFormModalVisible}
          reloadData={this.getData}
          reloadAfterCreate={this.reloadAfterCreate}
          editing={modalEditing}
          data={selectedData}
          onRef={(ref) => (this.formModal = ref)}
        />
        <StockHistoryFormModal
          visible={stockFormModalVisible}
          setVisible={this.setStockFormModalVisible}
          reloadAfterCreate={this.reloadAfterCreate}
          mode={stockChangeMode}
          itemId={selectedData.id}
          onRef={(ref) => (this.stockFormModal = ref)}
        />
      </div>
    );
  }
}

export default withRouter(ItemList);
