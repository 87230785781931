import React, { Component } from "react";
import { Modal, Form, Input, Row, Col } from "antd";
import { createEmployee, updateEmployee } from "../../services/employee";

class EmployeeFormModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disableOkButton: false,
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.prepareForm = this.prepareForm.bind(this);
        this.formRef = React.createRef();
    }

    async onFormSubmit() {
        const { data, editing, setVisible, reloadData, reloadAfterCreate } = this.props;
        await this.setState({
            disableOkButton: true
        })
        let values;
        let passed = true;
        try {
            values = await this.formRef.current.validateFields()
        }
        catch (err) {
            values = err.values
            passed = false
        }

        if (passed) {
            try {
                if (!editing) {
                    await createEmployee(values)
                    reloadAfterCreate();
                }
                else {
                    await updateEmployee(data['id'], values)
                    reloadData();
                }
                setVisible(false);

            }
            catch (err) {
            }
            finally {
                this.setState({
                    disableOkButton: false
                })
            }
        }
        else {
            this.setState({
                disableOkButton: false
            })
        }

    };

    componentDidMount() {
        this.props.onRef(this);
    }

    async prepareForm() {

    }

    render() {
        const { editing, visible, setVisible, data } = this.props;
        const { disableOkButton } = this.state;
        return (
            <Modal
                visible={visible}
                onCancel={() => { setVisible(false) }}
                onOk={this.onFormSubmit}
                title={editing ? "แก้ไขรายการ" : "เพิ่มรายการ"}
                okText={editing ? "แก้ไข" : "เพิ่ม"}
                cancelText={"ปิด"}
                keyboard={false}
                maskClosable={false}
                destroyOnClose={true}
                okButtonProps={{disabled: disableOkButton}}
            >
                <Form
                    ref={this.formRef}
                    layout={'vertical'}
                    initialValues={data}
                >
                    <Row gutter={12}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                hasFeedback
                                label="ชื่อ"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "กรุณาใส่ชื่อ"
                                    }
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                hasFeedback
                                label="รหัสผ่าน"
                                name="passcode"
                                rules={[
                                    {
                                        required: true,
                                        message: "กรุณาใส่รหัสผ่าน",
                                    },
                                    { min: 3, message: 'กรุณาตั้งรหัส 3 ตัวขึ้นไป' },
                                    { max: 10, message: 'กรุณาตั้งรหัสน้อยกว่า 10 ตัว' },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={12}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                hasFeedback
                                label="ค่าแรงต่อวัน"
                                name="day_shift_wage"
                                rules={[
                                    {
                                        required: true,
                                        message: "กรุณาใส่ค่าแรงต่อวัน"
                                    }
                                ]}>
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                hasFeedback
                                label="ค่าแรงต่อคืน"
                                name="night_shift_wage"
                                rules={[
                                    {
                                        required: true,
                                        message: "กรุณาใส่ค่าแรงต่อคืน"
                                    }
                                ]}>
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row gutter={12}>
                        <Col span={24}>
                            <Form.Item
                                hasFeedback
                                label="รายละเอียด"
                                name="description"
                                rules={[]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

export default EmployeeFormModal;