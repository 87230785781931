export const itemTypeMap = {
  SINGLE_DISH: "จานเดี่ยว",
  SIDE_DISH: "กับข้าว",
  INSTANT_CUP: "มาม่า/โจ๊ก",
  EGG: "ไข่",
  RICE: "ข้าว",
  DRINK: "เครื่องดื่ม",
  CIGARETTE: "บุหรี่",
  CONSUMABLE: "ของใช้",
  SNACK: "ขนม",
};

export const itemTypeChoices = [
  { name: "จานเดี่ยว", value: "SINGLE_DISH" },
  { name: "กับข้าว", value: "SIDE_DISH" },
  { name: "มาม่า/โจ๊ก", value: "INSTANT_CUP" },
  { name: "ไข่", value: "EGG" },
  { name: "ข้าว", value: "RICE" },
  { name: "เครื่องดื่ม", value: "DRINK" },
  { name: "บุหรี่", value: "CIGARETTE" },
  { name: "ของใช้", value: "CONSUMABLE" },
  { name: "ขนม", value: "SNACK" },
];
