import { getProfile, login, logout } from '../../services/api';

export function load_profile() {
    return async dispatch => {
        try {
            const res = await getProfile()
            await dispatch(profile_loaded(res))
            return
        }
        catch(e) {
            console.error(e)
            return
        }
    }
}

export function login_profile(username, password) {
    return async dispatch => {
        try {
            await login(username, password)
            const res = await getProfile()
            await dispatch(profile_loaded(res))
            return
        }
        catch(e) {
            console.error(e)
            return
        }
    }
}

export function logout_profile() {
    return async dispatch => {
        try {
            await logout()
            await dispatch(logged_out())
            return
        }
        catch(e) {
            console.error(e)
            return
        }
    }
}

function profile_loaded(profile) {
    return { type: 'PROFILE_LOADED', profile }
}

function logged_out() {
    return { type: 'LOGGED_OUT' }
}
