import axios from "axios";
import { convertSortDir } from "../utils";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const getRooms = async (q, filter_params, page, page_size, sort_by, sort_dir) => {

    let params = filter_params
    params['page'] = page
    params['page_size'] = page_size
    params['q'] = q
    params['sort_by'] = sort_by
    params['sort_dir'] = convertSortDir(sort_dir)

    const res = await axios.get(BACKEND_URL+'/rooms', {
        params,
        withCredentials: true
    });
    return res;
}
