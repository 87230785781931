import React, { Component } from 'react';
import Login from './pages/Login';
import MenuLayout from './pages/MenuLayout';
import { Redirect, Route, Switch, HashRouter as Router } from 'react-router-dom';
import BookingList from './pages/booking/BookingList';
import OrderList from './pages/order/OrderList';
import UserList from './pages/user/UserList';
import ItemList from './pages/item/ItemList';
import MealList from './pages/meal/MealList';
import RoomList from './pages/room/RoomList';
import StockHistory from './pages/item/stockHistory/StockHistory';
import Profile from './pages/Profile';
import StockReportList from './pages/stockReport/StockReportList';
import './App.css';
import 'antd/dist/antd.css';
import 'antd-button-color/dist/css/style.css';
import { load_profile } from './redux/actions/profile'
import { connect } from 'react-redux'
import EmployeeList from './pages/employee/EmployeeList';
import TimeSheetList from './pages/timeSheet/TimeSheetList';
import TimeSheetCalendar from './pages/timeSheet/TimeSheetCalendar';


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errorToasterVisible: false,
            errorToasterTitle: "",
            errorToasterMessage: ""
        }
    }

    componentDidMount() {
		const { dispatch } = this.props
		dispatch(load_profile())
	}

    render() {
        const {profile} = this.props
        return (
            <Router>
                <Switch>
                    <Route exact path="/login" render={() => <Login />} />
                    <Route path="/bookings" render={() => <MenuLayout displayComponent={<BookingList />} selectedMenu={"bookings"} />} />
                    <Route path="/time_sheets" render={() => <MenuLayout displayComponent={<TimeSheetCalendar />} selectedMenu={"time_sheets"} />} />
                    { profile.profile.role === "ADMIN" ? <Route path="/stocks" render={() => <MenuLayout displayComponent={<ItemList />} selectedMenu={"stocks"} />} /> : null }
                    { profile.profile.role === "ADMIN" ? <Route path="/stock_history/:id" render={() => <MenuLayout displayComponent={<StockHistory />} selectedMenu={"stocks"} />} /> : null }
                    { profile.profile.role === "ADMIN" ? <Route path="/users" render={() => <MenuLayout displayComponent={<UserList />} selectedMenu={"users"} />} /> : null }
                    { profile.profile.role === "ADMIN" ? <Route path="/stock_report" render={() => <MenuLayout displayComponent={<StockReportList />} selectedMenu={"stock_report"} />} /> : null }
                    { profile.profile.role === "ADMIN" ? <Route path="/room_report" render={() => <MenuLayout displayComponent={<RoomList />} selectedMenu={"room_report"} />} /> : null }
                    { profile.profile.role === "ADMIN" ? <Route path="/employees" render={() => <MenuLayout displayComponent={<EmployeeList />} selectedMenu={"employees"} />} /> : null }
                    <Route path="/sale_orders" render={() => <MenuLayout displayComponent={<OrderList orderType={"SELL"}/>} selectedMenu={"sale_orders"} />} />
                    {/* <Route path="/purchase_orders" render={() => <MenuLayout displayComponent={<OrderList orderType={"BUY"}/>} selectedMenu={"purchase_orders"} />} /> */}
                    <Route path="/meals" render={() => <MenuLayout displayComponent={<MealList />} selectedMenu={"meals"} />} />

                    <Route path="/profile" render={() => <MenuLayout displayComponent={<Profile />} selectedMenu={"profile"} />} />
                    
                    {/* <Route path="/" render={() => <MenuLayout/> } /> */}
                    <Route render={() => <Redirect to="/bookings" />} />
                </Switch>
            </Router>
        )
    }
}

const mapStateToProps = state => ({
	profile: state.profile
})


export default connect(mapStateToProps)(App);