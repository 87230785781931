import React, { Component } from 'react';
import { Table, Input, Row, Col, Button, Modal, DatePicker } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { getBookings, deleteBooking, checkoutBooking } from "../../services/booking";
import BookingFormModal from './BookingFormModal';
import moment from 'moment';
import { connect } from "react-redux";

const { Search } = Input;
const confirm = Modal.confirm;

class BookingList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            pageSize: 5,
            total: 10,
            sortBy: "created_at",
            sortDir: "descend",
            q: "",
            dataLoading: false,
            formModalVislble: false,
            modalEditing: false,
            selectedData: {},
            selectedDate: moment(new Date()).set('hour', 8).set('minute', 0).set('second', 0)
        }

        this.getData = this.getData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onRowEditClick = this.onRowEditClick.bind(this);
        this.onRowDeleteClick = this.onRowDeleteClick.bind(this);
        this.setFormModalVisible = this.setFormModalVisible.bind(this);
        this.reloadAfterCreate = this.reloadAfterCreate.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
        this.onRowCheckoutClick = this.onRowCheckoutClick.bind(this);
        this.getStatusText = this.getStatusText.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
    }

    onDateChange(value) {
        this.setState({
            selectedDate: value,
            page: 1
        }, () => {
            this.getData()
        })
    }

    onPageChange(page, pageSize) {
        this.setState({
            page: page,
            pageSize: pageSize
        }, () => {
            this.getData()
        })
    }

    onPageSizeChange(current, size) {
        this.setState({
            page: current,
            pageSize: size
        }, () => {
            this.getData()
        })
    }

    onTableChange(pagination, filters, sorter) {
        const { page, pageSize, sortBy, sortDir } = this.state
        this.setState({
            page: pagination['current'] || page,
            pageSize: pagination['pageSize'] || pageSize,
            sortBy: sorter['columnKey'] || sortBy,
            sortDir: sorter['order'] || sortDir,
        }, () => {
            this.getData()
        })
    };

    onSearch(value) {
        this.setState({
            q: value,
            page: 1
        }, () => {
            this.getData()
        })
    }

    async getData() {
        this.setState({
            dataLoading: true
        }, async () => {
            const { q, page, pageSize, sortBy, sortDir, selectedDate } = this.state
            let fromDate = moment(selectedDate);
            let toDate = moment(selectedDate);
            // Calculate selected date
            fromDate = fromDate.set('hour', 8).set('minute', 0).set('second', 0).format("YYYY-MM-DD HH:mm:ss")
            toDate = toDate.add(1, 'days').set('hour', 7).set('minute', 59).set('second', 0).format("YYYY-MM-DD HH:mm:ss")
            let result = await getBookings(q, {
                // "from_date": fromDate,
                // "to_date": toDate,
            }, page, pageSize, sortBy, sortDir)
            const bookings = result.data.bookings.map(b => {
                b['display_room'] = b.room
                return b
            })
            this.setState({
                data: bookings,
                total: result.data.total,
                dataLoading: false
            })
        })
    }

    async reloadAfterCreate() {
        // const { pageSize, page, total } = this.state
        // let totalPages = Math.ceil(total/pageSize)
        // if (total === pageSize && page === totalPages){
        //     totalPages += 1
        // }

        this.setState({
            page: 1
        }, () => {
            this.getData()
        })
    }

    onRowEditClick(row) {
        // row['check_in_at'] = moment(row['check_in_at'])
        const prepareForm = this.formModal.prepareForm
        if (row.room.startsWith("A") || row.room.startsWith("B") || row.room.startsWith("C")){
            row['room_number'] = row.room[1]
            row['room'] = row.room[0]
        }
        
        this.setState({
                formModalVislble: true,
                selectedData: row,
                modalEditing: true
        }, () => {
            setTimeout(function () { prepareForm(); }, 100);
        });
    }

    onRowDeleteClick(row, lastRow, onPageChange, page, pageSize) {
        confirm({
            title: `ต้องการลบ ${row.booking_number} ห้อง ${row.display_room} หรือไม่?`,
            async onOk() {
                let response = await deleteBooking(row.id);
                if (response.status === 200) {
                    if (lastRow && page > 1) {
                        page -= 1
                    }
                    await onPageChange(page, pageSize);
                }
            },
            onCancel() { }

        });
    }

    onRowCheckoutClick(row, onPageChange, page, pageSize) {
        confirm({
            title: `ต้องการเช็คเอาท์ห้อง ${row.display_room} หรือไม่?`,
            async onOk() {
                let response = await checkoutBooking(row.id);
                if (response.status === 200) {
                    await onPageChange(page, pageSize);
                }
            },
            onCancel() { }

        });
    }

    setFormModalVisible(val) {
        this.setState({
            formModalVislble: val,
            selectedData: {}
        })
    }

    getStatusText(status) {
        switch(status) {
            case "CHECKIN":
                return <span style={{color: '#ffcc00'}}>เช็คอิน</span>
                break;
            case "CHECKOUT":
                return <span style={{color: 'green'}}>เช็คเอาท์</span>
                break;
            case "CANCELLED":
                return <span style={{color: 'red'}}>ยกเลิก</span>
                break;
            default:
              return status
          }
    }

    componentWillMount() {
        this.getData()
    }

    render() {
        const { profile } = this.props
        const { data, total, page, pageSize, dataLoading, formModalVislble, selectedData, modalEditing, selectedDate } = this.state

        let tableColumns = [
            {

                title: 'หมายเลข',
                dataIndex: 'booking_number',
                key: 'booking_number',
                sorter: true,
                render: (text, row) => text.substr(text.length - 3)
            },
            {

                title: 'ห้อง',
                dataIndex: 'display_room',
                key: 'display_room',
                sorter: true
            },
            {

                title: 'เวลาเข้า',
                dataIndex: 'check_in_at',
                key: 'check_in_at',
                render: (text, row) => <div>
                    <Row>
                        <span>{moment(text).format("DD/MM/YYYY")}</span>
                    </Row>
                    <Row>
                        <span>{moment(text).format("HH:mm")}</span>
                    </Row>
                </div>,
                sorter: true
            },
            {

                title: 'เวลาออก',
                dataIndex: 'check_out_at',
                key: 'check_out_at',
                render: (text, row) => (
                    <div style={moment(text) <= moment() && row.status == "CHECKIN" ? { color: 'red' } : {}}>
                        <Row>
                            <span>{moment(text).format("DD/MM/YYYY")}</span>
                        </Row>
                        <Row>
                            <span>{moment(text).format("HH:mm")}</span>
                        </Row>
                    </div>
                ),
                sorter: true
            },
            {
                title: 'ราคา',
                dataIndex: 'price',
                key: 'price',
                sorter: true
            },
            {
                title: 'สถานะ',
                dataIndex: 'status',
                key: 'status',
                sorter: true,
                render: (text, row) => (
                    <div> {this.getStatusText(row.status)} </div>
                ),
                sorter: true
            },
            {
                title: 'ตัวเลือก',
                dataIndex: 'action',
                key: 'action',
                render: (text, row) => (
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        {row.status === "CHECKIN" ? 
                                <Button
                                type="primary"
                                shape="circle"
                                size="large"
                                icon={<ClockCircleOutlined />}
                                onClick={() => this.onRowCheckoutClick(row, this.onPageChange, page, pageSize)}
                                style={{ marginRight: 5 }}
                            />
                            : null }
                        { profile.profile.role === "ADMIN" ? 
                            <Button
                                type="default"
                                shape="circle"
                                size="large"
                                icon={<EditOutlined />}
                                onClick={() => this.onRowEditClick(row)}
                                style={{ marginRight: 5 }}/>
                            : null }
                        { profile.profile.role === "ADMIN" ? 
                            <Button
                                type="danger"
                                shape="circle"
                                size="large"
                                icon={<DeleteOutlined />}
                                onClick={() => this.onRowDeleteClick(row, data.length === 1, this.onPageChange, page, pageSize)}
                                style={{ marginRight: 5 }}/> 
                            : null }
                    </div>
                )
            }
        ]

        return (
            <div>
                {/* <Row>
                    <Col span={3} xs={1} sm={1} md={3} lg={3} xl={3} xxl={3}/>
                    <Col span={8} xs={9} sm={9} md={8} lg={8} xl={8} xxl={8}>
                        <DatePicker onChange={this.onDateChange} defaultValue={selectedDate} style={{width: "100%"}}/>
                    </Col>
                    <Col span={2} xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}/>
                    <Col span={8} xs={9} sm={9} md={8} lg={8} xl={8} xxl={8}>
                        <Search
                            placeholder="ค้นหาด้วยหมายเลข"
                            onSearch={this.onSearch}
                        />
                    </Col>
                    <Col span={1} offset={2}>
                        <Button
                            style={{ float: 'right' }}
                            size="large"
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => this.setState({ formModalVislble: true, modalEditing: false })}
                        />
                    </Col>
                </Row> */}
                <Row>
                    <Col span={8} xs={6} sm={6} md={8} lg={8} xl={8} xxl={8}/>
                    <Col span={10} xs={12} sm={12} md={10} lg={10} xl={10} xxl={10}>
                        <Search
                            placeholder="ค้นหาด้วยหมายเลข"
                            onSearch={this.onSearch}
                        />
                    </Col>
                    <Col span={1} offset={5}>
                        <Button
                            style={{ float: 'right' }}
                            size="large"
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => this.setState({ formModalVislble: true, modalEditing: false })}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={24}>
                        <Table
                            rowKey={'id'}
                            columns={tableColumns}
                            dataSource={data}
                            bordered
                            locale={{ filterConfirm: 'OK', filterReset: 'รีเซ็ต', emptyText: 'ไม่พบข้อมูล' }}
                            pagination={{ current: page, pageSize: pageSize, total: total, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '50'] }}
                            loading={dataLoading}
                            onChange={this.onTableChange}
                        />
                    </Col>
                </Row>

                <BookingFormModal
                    visible={formModalVislble}
                    setVisible={this.setFormModalVisible}
                    reloadData={this.getData}
                    reloadAfterCreate={this.reloadAfterCreate}
                    editing={modalEditing}
                    data={selectedData}
                    onRef={ref => (this.formModal = ref)}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      profile: state.profile
    };
}
  
export default connect(mapStateToProps)(BookingList);
