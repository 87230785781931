const initialState = {
    profile: {}
}

function profile(state = initialState, action) {
    switch (action.type) {

        case 'PROFILE_LOADED': {
            const { profile } = action
            const data = profile.data   
            if (data.success === true) {
                return { ...state, profile: data.user }
            }
            
            return { ...state, profile: {} }
        }
        case 'LOGGED_OUT': {  
            return { ...state, profile: {} }
        }
        default:
            return state
    }
}

export default profile