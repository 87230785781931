import React, { Component } from "react";
import { Table, Input, Row, Col, Button, Modal, Checkbox, Select } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  RedoOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { getMeals, deleteMeal, restoreMeal } from "../../services/meal";
import MealFormModal from "./MealFormModal";
import moment from "moment";
import { itemTypeMap, itemTypeChoices } from "../../utils/itemType";

const { Option } = Select;
const { Search } = Input;
const confirm = Modal.confirm;

class MealList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      pageSize: 5,
      total: 10,
      sortBy: "created_at",
      sortDir: "descend",
      q: "",
      dataLoading: false,
      formModalVislble: false,
      modalEditing: false,
      selectedData: {},
    };

    this.getData = this.getData.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onRowEditClick = this.onRowEditClick.bind(this);
    this.onRowDeleteClick = this.onRowDeleteClick.bind(this);
    this.setFormModalVisible = this.setFormModalVisible.bind(this);
    this.reloadAfterCreate = this.reloadAfterCreate.bind(this);
    this.onTableChange = this.onTableChange.bind(this);
    this.isRecommendedCheck = this.isRecommendedCheck.bind(this);
    this.onItemTypeSelected = this.onItemTypeSelected.bind(this);
  }

  onPageChange(page, pageSize) {
    this.setState(
      {
        page: page,
        pageSize: pageSize,
      },
      () => {
        this.getData();
      }
    );
  }

  onPageSizeChange(current, size) {
    this.setState(
      {
        page: current,
        pageSize: size,
      },
      () => {
        this.getData();
      }
    );
  }

  onTableChange(pagination, filters, sorter) {
    const { page, pageSize, sortBy, sortDir } = this.state;
    this.setState(
      {
        page: pagination["current"] || page,
        pageSize: pagination["pageSize"] || pageSize,
        sortBy: sorter["columnKey"] || sortBy,
        sortDir: sorter["order"] || sortDir,
      },
      () => {
        this.getData();
      }
    );
  }

  onSearch(value) {
    this.setState(
      {
        q: value,
        page: 1,
      },
      () => {
        this.getData();
      }
    );
  }

  isRecommendedCheck(e) {
    this.setState(
      {
        isRecommended: e.target.checked,
        page: 1,
      },
      () => {
        this.getData();
      }
    );
  }

  onAddItemSelected(e, val) {
    this.setState({
      addItemSelectId: e,
    });
  }

  onItemTypeSelected(e) {
    this.setState(
      {
        selectedItemType: e,
        page: 1,
      },
      () => {
        this.getData();
      }
    );
  }

  async getData() {
    this.setState(
      {
        dataLoading: true,
      },
      async () => {
        const { q, page, pageSize, isRecommended, selectedItemType, sortBy, sortDir } =
          this.state;
        const paramObj = {'meal.item_type': selectedItemType};
        if (isRecommended) {
          paramObj["meal.is_recommended"] = true;
        }
        let result = await getMeals(
          q,
          paramObj,
          page,
          pageSize,
          sortBy,
          sortDir
        );
        this.setState({
          data: result.data.meals,
          total: result.data.total,
          dataLoading: false,
        });
      }
    );
  }

  async reloadAfterCreate() {
    this.setState(
      {
        page: 1,
      },
      () => {
        this.getData();
      }
    );
  }

  onRowEditClick(row) {
    row["check_in_at"] = moment(row["check_in_at"]);
    const prepareForm = this.formModal.prepareForm;
    this.setState(
      {
        formModalVislble: true,
        selectedData: row,
        modalEditing: true,
      },
      () => {
        setTimeout(function () {
          prepareForm();
        }, 100);
      }
    );
  }

  onRowDeleteClick(row, onPageChange, page, pageSize) {
    confirm({
      title: `ต้องการ Deactivate ${row.name} หรือไม่?`,
      async onOk() {
        let response = await deleteMeal(row.id);
        if (response.status === 200) {
          await onPageChange(page, pageSize);
        }
      },
      onCancel() {},
    });
  }

  onRowRestoreClick(row, onPageChange, page, pageSize) {
    confirm({
      title: `ต้องการ Activate ${row.name} หรือไม่?`,
      async onOk() {
        let response = await restoreMeal(row.id);
        if (response.status === 200) {
          await onPageChange(page, pageSize);
        }
      },
      onCancel() {},
    });
  }

  setFormModalVisible(val) {
    this.setState({
      formModalVislble: val,
      selectedData: {},
    });
  }

  componentWillMount() {
    this.getData();
  }

  render() {
    const {
      data,
      total,
      page,
      pageSize,
      dataLoading,
      formModalVislble,
      selectedData,
      modalEditing,
    } = this.state;

    let tableColumns = [
      {
        title: "ชื่อ",
        dataIndex: "name",
        key: "name",
        sorter: true,
      },
      {
        title: "ราคา",
        dataIndex: "price",
        key: "price",
        sorter: true,
      },
      {
        title: "ประเภท",
        dataIndex: "item_type",
        key: "item_type",
        sorter: true,
        render: (text, row) => itemTypeMap[text],
      },
      {
        title: "สินค้าแนะนำ",
        dataIndex: "is_recommended",
        key: "is_recommended",
        render: (text, row) => (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {text ? (
              <CheckCircleOutlined style={{ color: "#52C41A" }} />
            ) : (
              <CloseCircleOutlined style={{ color: "#FF4D4F" }} />
            )}
          </div>
        ),
      },
      {
        title: "หมายเหตุ",
        dataIndex: "description",
        key: "description",
        sorter: true,
      },
      {
        title: "ขายได้อีก",
        dataIndex: "current_stock",
        key: "current_stock",
        sorter: true,
        render: (text, row) => (
          <span>
            {text} {row.unit}
          </span>
        ),
      },
      {
        title: "วัตถุดิบใกล้หมด",
        dataIndex: "lowest_stock_ingredient",
        key: "lowest_stock_ingredient",
        render: (text, row) => (
          <span>
            {row.lowest_stock_ingredient} เหลือ {row.lowest_stock_amount}{" "}
            {row.lowest_stock_unit}
          </span>
        ),
      },
      {
        title: "ตัวเลือก",
        dataIndex: "action",
        key: "action",
        render: (text, row) => (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              type="default"
              shape="circle"
              size="small"
              icon={<EditOutlined />}
              onClick={() => this.onRowEditClick(row)}
              style={{ marginRight: 5 }}
            />

            <Button
              type={row.is_active ? "danger" : "primary"}
              shape="circle"
              size="small"
              icon={row.is_active ? <DeleteOutlined /> : <RedoOutlined />}
              onClick={
                row.is_active
                  ? () =>
                      this.onRowDeleteClick(row, this.getData, page, pageSize)
                  : () =>
                      this.onRowRestoreClick(row, this.getData, page, pageSize)
              }
            />
          </div>
        ),
      },
    ];

    return (
      <div>
        <Row>
          <Col span={10}>
            <Search placeholder="ค้นหาด้วยชื่อ" onSearch={this.onSearch} />
          </Col>
          <Col span={6} offset={2}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="เลือกประเภท"
              optionFilterProp="children"
              onSelect={this.onItemTypeSelected}
              onClear={this.onItemTypeSelected}
              allowClear
            >
              {itemTypeChoices.map((itc) => (
                <Option key={itc.value} value={itc.value}>
                  {itc.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={3} offset={1}>
            <Checkbox onChange={(e) => this.isRecommendedCheck(e)}>
              สินค้าแนะนำ
            </Checkbox>
          </Col>
          <Col span={1} offset={1}>
            <Button
              style={{ float: "right" }}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                this.setState(
                  { formModalVislble: true, modalEditing: false },
                  () => this.formModal.prepareForm()
                );
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <Table
              rowKey={"id"}
              columns={tableColumns}
              dataSource={data}
              bordered
              locale={{
                filterConfirm: "OK",
                filterReset: "รีเซ็ต",
                emptyText: "ไม่พบข้อมูล",
              }}
              pagination={{
                current: page,
                pageSize: pageSize,
                total: total,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20", "50"],
              }}
              loading={dataLoading}
              onChange={this.onTableChange}
            />
          </Col>
        </Row>

        <MealFormModal
          visible={formModalVislble}
          setVisible={this.setFormModalVisible}
          reloadData={this.getData}
          reloadAfterCreate={this.reloadAfterCreate}
          editing={modalEditing}
          data={selectedData}
          onRef={(ref) => (this.formModal = ref)}
        />
      </div>
    );
  }
}

export default MealList;
