import React, { Component } from 'react';
import { Table, Input, Row, Col, Button, Modal } from 'antd'
import { BarsOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { getOrders, deleteOrder } from "../../services/order";
import OrderFormModal from './OrderFormModal';
import moment from 'moment';

const { Search } = Input;
const confirm = Modal.confirm;

class OrderList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            pageSize: 5,
            total: 10,
            sortBy: "created_at",
            sortDir: "descend",
            q: "",
            dataLoading: false,
            formModalVislble: false,
            modalEditing: false,
            selectedData: {}
        }

        this.getData = this.getData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onRowEditClick = this.onRowEditClick.bind(this);
        this.onRowDeleteClick = this.onRowDeleteClick.bind(this);
        this.setFormModalVisible = this.setFormModalVisible.bind(this);
        this.reloadAfterCreate = this.reloadAfterCreate.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
    }

    onPageChange(page, pageSize) {
        this.setState({
            page: page,
            pageSize: pageSize
        }, () => {
            this.getData()
        })
    }

    onPageSizeChange(current, size) {
        this.setState({
            page: current,
            pageSize: size
        }, () => {
            this.getData()
        })
    }

    onTableChange(pagination, filters, sorter) {
        const { page, pageSize, sortBy, sortDir } = this.state
        this.setState({
            page: pagination['current'] || page,
            pageSize: pagination['pageSize'] || pageSize,
            sortBy: sorter['columnKey'] || sortBy,
            sortDir: sorter['order'] || sortDir,
        }, () => {
            this.getData()
        })
    };

    onSearch(value) {
        this.setState({
            q: value,
            page: 1
        }, () => {
            this.getData()
        })
    }

    async getData() {
        this.setState({
            dataLoading: true
        }, async () => {
            const { q, page, pageSize, sortBy, sortDir } = this.state
            const { orderType } = this.props
            let result = await getOrders(q, {'order.is_active': true, 'order.type': orderType}, page, pageSize, sortBy, sortDir)
            this.setState({
                data: result.data.orders,
                total: result.data.total,
                dataLoading: false
            })
        })
    }

    async reloadAfterCreate() {
        // const { pageSize, page, total } = this.state
        // let totalPages = Math.ceil(total/pageSize)
        // if (total === pageSize && page === totalPages){
        //     totalPages += 1
        // }

        this.setState({
            page: 1
        }, () => {
            this.getData()
        })
    }

    onRowEditClick(row) {
        row['check_in_at'] = moment(row['check_in_at'])
        const prepareForm = this.formModal.prepareForm
        this.setState({
            formModalVislble: true,
            selectedData: row,
            modalEditing: true
        }, () => {
            setTimeout(function () { prepareForm(); }, 100);
        });
    }

    onRowDeleteClick(row, lastRow, onPageChange, page, pageSize) {
        confirm({
            title: `ต้องการลบ ${row.order_number} หรือไม่?`,
            content: 'สินค้าทั้งหมดจะถูกคืนเข้าคลัง',
            async onOk() {
                let response = await deleteOrder(row.id);
                if (response.status === 200) {
                    if (lastRow && page > 1) {
                        page -= 1
                    }
                    await onPageChange(page, pageSize);
                }
            },
            onCancel() { }

        });
    }

    setFormModalVisible(val) {
        this.setState({
            formModalVislble: val,
            selectedData: {}
        })
    }

    componentWillMount() {
        this.getData()
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        // if (nextProps.startTime !== this.state.startTime) {
        //   this.setState({ startTime: nextProps.startTime });
        // }
        this.getData()
      }

    render() {
        const { data, total, page, pageSize, dataLoading, formModalVislble, selectedData, modalEditing } = this.state
        const { orderType } = this.props

        let tableColumns = [
            {

                title: 'หมายเลข',
                dataIndex: 'order_number',
                key: 'order_number',
                sorter: true,
                render: (text, row) => (
                    <a onClick={() => this.onRowEditClick(row)}>{text}</a>
                )
            },
            {
                title: 'ราคา',
                dataIndex: 'total_price',
                key: 'total_price',
                sorter: true
            },
            {

                title: 'เวลา',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text, row) => moment(text).format("DD/MM/YYYY HH:mm"),
                sorter: true
            },
            {
                title: 'หมายเหตุ',
                dataIndex: 'description',
                key: 'description',
                sorter: true
            },
            {
                title: 'ตัวเลือก',
                dataIndex: 'action',
                key: 'action',
                render: (text, row) => (
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            type="danger"
                            shape="circle"
                            size="small"
                            icon={<DeleteOutlined />}
                            onClick={() => this.onRowDeleteClick(row, data.length === 1, this.onPageChange, page, pageSize)}
                            style={{ marginRight: 5 }}
                        />
                    </div>
                )
            }

        ]

        return (
            <div>
                <Row>
                    <Col span={10} offset={8}>
                        <Search
                            placeholder="ค้นหาด้วยหมายเลข"
                            onSearch={this.onSearch}
                        />
                    </Col>
                    <Col span={1} offset={5}>
                        <Button
                            style={{ float: 'right' }}
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() =>  {
                                    this.setState({ formModalVislble: true, modalEditing: false }, () => this.formModal.prepareForm())
                                }
                            }
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={24}>
                        <Table
                            rowKey={'id'}
                            columns={tableColumns}
                            dataSource={data}
                            bordered
                            locale={{ filterConfirm: 'OK', filterReset: 'รีเซ็ต', emptyText: 'ไม่พบข้อมูล' }}
                            pagination={{ current: page, pageSize: pageSize, total: total, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '50'] }}
                            loading={dataLoading}
                            onChange={this.onTableChange}
                        />
                    </Col>
                </Row>

                <OrderFormModal
                    visible={formModalVislble}
                    setVisible={this.setFormModalVisible}
                    reloadData={this.getData}
                    reloadAfterCreate={this.reloadAfterCreate}
                    editing={modalEditing}
                    data={selectedData}
                    orderType={orderType}
                    onRef={ref => (this.formModal = ref)}
                />
            </div>
        );
    }
}

export default OrderList;