import React, { Component } from "react";
import { Modal, Form, Input, Row, Col, Select, DatePicker } from "antd";
import { getItems, addStock, subStock } from "../../services/item";

const { Option } = Select;

class StockReportFormModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            itemChoices: []
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.preFillDescription = this.preFillDescription.bind(this);
        this.getChoices = this.getChoices.bind(this);
        this.formRef = React.createRef();
    }

    preFillDescription(mode) {
        const defVal = mode === "ADD" ? "ซื้อเข้า" : "ขายออก"
        this.setState({
            desDefValue: defVal
        })
    }

    async onFormSubmit() {
        const { setVisible, reloadAfterCreate, mode, selectedDate } = this.props;
        let values;
        let passed = true;
        try {
            values = await this.formRef.current.validateFields()
        }
        catch (err) {
            values = err.values
            passed = false
        }
        // Have not click & selected dare
        if (values["stock_change_at"] === undefined){
            values["stock_change_at"] = selectedDate
        }
        values["stock_change_at"] = values["stock_change_at"].format("YYYY-MM-DD HH:mm:ss")
        if (passed) {
            if (mode === "ADD") {
                await addStock(values.item, values)
            }
            else if (mode === "SUB") {
                await subStock(values.item, values)
            }
            reloadAfterCreate();
            setVisible(false);
        }
    };

    async getChoices() {
        let result = await getItems("", {'item.is_active': true}, 1, 1000000, "name", "ascend")
        this.setState({
            itemChoices: result.data.items
        })
    }

    componentWillMount() {
        this.getChoices()
    }
    
    componentDidMount() {
        this.props.onRef(this);
    }

    render() {
        const { mode, visible, setVisible, selectedDate } = this.props;
        const { desDefValue, itemChoices } = this.state;
        return (
            <Modal
                visible={visible}
                onCancel={() => { setVisible(false) }}
                onOk={this.onFormSubmit}
                okButtonProps={{type: mode === "ADD" ? "success" : "danger"}}
                width={'70vw'}
                title={mode === "ADD" ? "เพิ่มคลัง" : "ลดคลัง"}
                okText={mode === "ADD" ? "เพิ่ม" : "ลด"}
                cancelText={"ปิด"}
                keyboard={false}
                maskClosable={false}
                destroyOnClose={true}
            >
                <Form
                    ref={this.formRef}
                    layout={'vertical'}
                >
                    <Row gutter={12}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                hasFeedback
                                label="สินค้า"
                                name="item"
                                rules={[
                                    {
                                        required: true,
                                        message: "กรุณาใส่จำนวน"
                                    }
                                ]}>
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="กรุณาเลือกสินค้า"
                                    optionFilterProp="children"
                                >
                                    {itemChoices.map(itc =>
                                        <Option key={itc.id} value={itc.id}>{itc.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                hasFeedback
                                label="จำนวน"
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: "กรุณาใส่จำนวน"
                                    }
                                ]}>
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                hasFeedback
                                label="เวลา"
                                name="stock_change_at"
                                rules={[]}>
                                <DatePicker defaultValue={selectedDate} style={{width: "100%"}} showTime/>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row gutter={12}>
                        <Col span={24}>
                            <Form.Item
                                hasFeedback
                                label="คำอธิบาย"
                                name="description"
                                initialValue={desDefValue}
                                rules={[
                                    {
                                        message: "กรุณาใส่คำอธิบาย"
                                    }
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

export default StockReportFormModal;