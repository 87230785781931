import React, { Component } from "react";
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Table,
  Divider,
  Checkbox
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { getItems } from "../../services/item";
import { createMeal, updateMeal } from "../../services/meal";
import { itemTypeChoices } from "../../utils/itemType";

const { Option } = Select;

class MealFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ingredients: [],
      ingredientChoices: [],
      addItemSelectId: undefined,
      addIngredientAmount: 1,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onIngredientSearch = this.onIngredientSearch.bind(this);
    this.onAddItemSelected = this.onAddItemSelected.bind(this);
    this.onAddIngrdientButtonClick = this.onAddIngrdientButtonClick.bind(this);
    this.onAddItemAmountChange = this.onAddItemAmountChange.bind(this);
    this.prepareForm = this.prepareForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.formRef = React.createRef();
  }

  async onFormSubmit() {
    const { ingredients } = this.state;
    const {
      data,
      editing,
      setVisible,
      reloadData,
      reloadAfterCreate,
      orderType,
    } = this.props;
    let values;
    let passed = true;
    try {
      values = await this.formRef.current.validateFields();
    } catch (err) {
      values = err.values;
      passed = false;
    }

    values["meal_ingredients"] = ingredients.map((item) => ({
      item_id: item.id,
      amount: item.amount,
    }));

    if (passed) {
      if (!editing) {
        await createMeal(values);
        reloadAfterCreate();
      } else {
        await updateMeal(data["id"], values);
        reloadData();
      }
      setVisible(false);
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  async onIngredientSearch(val) {
    let result = await getItems(
      val,
      { "item.is_active": true, "item.is_ingredient": true },
      1,
      50,
      "name",
      "ASC"
    );
    this.setState({
      ingredientChoices: result.data.items,
    });
  }

  async prepareForm() {
    const { data, editing } = this.props;
    let ingredients;
    if (editing) {
      ingredients = data["ingredients"].map((ing) => ({
        id: ing.item.id,
        name: ing.item.name,
        amount: ing.amount,
        unit: ing.unit,
      }));
      this.setState({
        ingredients: ingredients,
      });
    }
    await this.onIngredientSearch("");
  }

  onAddItemSelected(e, val) {
    this.setState({
      addItemSelectId: e,
    });
  }

  onAddIngrdientButtonClick() {
    const {
      addItemSelectId,
      ingredientChoices,
      ingredients,
      addIngredientAmount,
    } = this.state;
    const addAmount = parseInt(addIngredientAmount) || 1;
    for (let i = 0; i < ingredientChoices.length; i++) {
      if (ingredientChoices[i].id === addItemSelectId) {
        let ingredientExist = false;
        for (let j = 0; j < ingredients.length; j++) {
          if (ingredientChoices[i].id === ingredients[j].id) {
            ingredientExist = true;
            ingredients[j].amount += addAmount;
          }
        }
        if (!ingredientExist) {
          ingredients.push({
            id: ingredientChoices[i].id,
            name: ingredientChoices[i].name,
            amount: addAmount,
          });
        }
      }
    }

    this.setState(
      {
        ingredients: ingredients,
      },
      () => {
        this.setState({
          addIngredientAmount: 1,
        });
      }
    );
  }

  onAddItemAmountChange(e) {
    this.setState({
      addIngredientAmount: parseInt(e.target.value),
    });
  }

  onRowAmountChange(e, row, overrideEmpty) {
    const { ingredients } = this.state;
    const amount = overrideEmpty
      ? parseInt(e.target.value) || 1
      : parseInt(e.target.value);
    for (let i = 0; i < ingredients.length; i++) {
      if (row.id === ingredients[i].id) {
        ingredients[i].amount = amount;
      }
    }
    this.setState({
      ingredients: ingredients,
    });
  }

  onRowDeleteClick(row) {
    const { ingredients } = this.state;
    for (let i = 0; i < ingredients.length; i++) {
      if (row.id === ingredients[i].id) {
        ingredients.splice(i, 1);
        break;
      }
    }
    this.setState({
      ingredients: ingredients,
    });
  }

  clearForm() {
    // Clear state on close
    this.setState({
      ingredients: [],
      ingredientChoices: [],
      addItemSelectId: undefined,
      addIngredientAmount: 1,
    });
  }

  render() {
    const { editing, visible, setVisible, data } = this.props;
    const { ingredientChoices, ingredients, addIngredientAmount } = this.state;

    let itemTableColumns = [
      {
        title: "ชื่อ",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "จำนวน",
        dataIndex: "amount",
        key: "amount",
        width: 150,
        render: (text, row) => (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Input
              value={row.amount}
              type="number"
              onChange={(e) => this.onRowAmountChange(e, row, false)}
              onBlur={(e) => this.onRowAmountChange(e, row, true)}
              style={{ marginRight: 10 }}
            />{" "}
            <span>{row.unit}</span>
          </div>
        ),
      },
      {
        title: "ตัวเลือก",
        dataIndex: "action",
        key: "action",
        width: 150,
        render: (text, row) => (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              type="danger"
              shape="circle"
              size="small"
              icon={<DeleteOutlined />}
              onClick={() => this.onRowDeleteClick(row)}
              style={{ marginRight: 5 }}
            />
          </div>
        ),
      },
    ];

    return (
      <Modal
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={this.onFormSubmit}
        width={"70vw"}
        style={{ maxWidth: 800, minWidth: 600 }}
        title={editing ? `แก้ไขรายการ ${data.name}` : "เพิ่มรายการ"}
        okText={editing ? "แก้ไข" : "เพิ่ม"}
        afterClose={this.clearForm}
        cancelText={"ปิด"}
        keyboard={false}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form ref={this.formRef} layout={"vertical"} initialValues={data}>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                label="ชื่อ"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "กรุณาใส่ชื่อ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hasFeedback
                label="ราคาต่อหน่วย"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "กรุณาใส่ราคา",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                label="หน่วย"
                name="unit"
                rules={[
                  {
                    required: true,
                    message: "กรุณาใส่หน่วย",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item hasFeedback label="ประเภทสินค้า" name="item_type">
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="เลือกประเภทสินค้า"
              optionFilterProp="children"
            >
              {itemTypeChoices.map((itc) => (
                <Option key={itc.value} value={itc.value}>
                  {itc.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                name="is_recommended"
                valuePropName="checked"
                style={{ justifyContent: "left", display: "flex" }}
              >
                <Checkbox> สินค้าแนะนำ</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item hasFeedback label="หมายเหตุ" name="description">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Divider>ส่วนผสม</Divider>
          <Row gutter={12} style={{ marginBottom: 25 }}>
            <Col span={12}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="กรุณาเลือกสินค้า"
                optionFilterProp="children"
                onSearch={this.onIngredientSearch}
                onSelect={this.onAddItemSelected}
              >
                {ingredientChoices.map((cc) => (
                  <Option key={cc.id} value={cc.id}>
                    {cc.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={6}>
              <Input
                type="number"
                min={1}
                onChange={this.onAddItemAmountChange}
                value={addIngredientAmount}
              />
            </Col>
            <Col span={6}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={this.onAddIngrdientButtonClick}
              />
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={24}>
              <Table
                rowKey={"id"}
                columns={itemTableColumns}
                dataSource={[...ingredients]}
                bordered
                locale={{
                  filterConfirm: "OK",
                  filterReset: "รีเซ็ต",
                  emptyText: "ไม่พบข้อมูล",
                }}
                pagination={{ hideOnSinglePage: true }}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default MealFormModal;
