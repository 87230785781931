import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Table, Row, Col, DatePicker  } from 'antd';
import Button from "antd-button-color";
import { PlusOutlined, MinusOutlined, PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { getStockChanges, createStockChange } from "../../services/stockChange";
import moment from 'moment';
import StockReportFormModal from './StockReportFormModal';

class StockReportList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            pageSize: 100,
            total: 10,
            sortBy: "created_at",
            sortDir: "ascend",
            q: "",
            dataLoading: false,
            formModalVislble: false,
            modalEditing: false,
            stockChangeMode: "ADD",
            selectedDate: moment(new Date()).set('hour', 8).set('minute', 0).set('second', 0)
        }

        this.getData = this.getData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.setFormModalVisible = this.setFormModalVisible.bind(this);
        this.reloadAfterCreate = this.reloadAfterCreate.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
        this.onCreateStockChangeClicked = this.onCreateStockChangeClicked.bind(this);
    }

    onPageChange(page, pageSize) {
        this.setState({
            page: page,
            pageSize: pageSize
        }, () => {
            this.getData()
        })
    }

    onPageSizeChange(current, size) {
        this.setState({
            page: current,
            pageSize: size
        }, () => {
            this.getData()
        })
    }

    onTableChange(pagination, filters, sorter) {
        const { page, pageSize, sortBy, sortDir } = this.state
        this.setState({
            page: pagination['current'] || page,
            pageSize: pagination['pageSize'] || pageSize,
            sortBy: sorter['columnKey'] || sortBy,
            sortDir: sorter['order'] || sortDir,
        }, () => {
            this.getData()
        })
    };

    onDateChange(value) {
        this.setState({
            selectedDate: value,
            page: 1
        }, () => {
            this.getData()
        })
    }

    async getData() {
        this.setState({
            dataLoading: true
        }, async () => {
            const { q, page, pageSize, sortBy, sortDir, selectedDate } = this.state
            let fromDate = moment(selectedDate);
            let toDate = moment(selectedDate);
            // Calculate selected date
            fromDate = fromDate.set('hour', 8).set('minute', 0).set('second', 0).format("YYYY-MM-DD HH:mm:ss")
            toDate = toDate.add(1, 'days').set('hour', 7).set('minute', 59).set('second', 0).format("YYYY-MM-DD HH:mm:ss")
            let result = await getStockChanges(q, 
                {
                    "from_date": fromDate,
                    "to_date": toDate,
                }, page, pageSize, sortBy, sortDir)
            this.setState({
                data: result.data.stock_changes,
                total: result.data.total,
                dataLoading: false
            })
        })
    }

    async reloadAfterCreate() {
        // const { pageSize, page, total } = this.state
        // let totalPages = Math.ceil(total/pageSize)
        // if (total === pageSize && page === totalPages){
        //     totalPages += 1
        // }

        this.setState({
            page: 1
        }, () => {
            this.getData()
        })
    }

    setFormModalVisible(val) {
        this.setState({
            formModalVislble: val
        })
    }

    onCreateStockChangeClicked(mode) {
        this.formModal.preFillDescription(mode)
        this.setState({
            stockChangeMode: mode,
            formModalVislble: true
        })
    }

    componentWillMount() {
        this.getData()
    }

    render() {

        const { data, total, page, pageSize, dataLoading, selectedDate, formModalVislble, stockChangeMode } = this.state

        let tableColumns = [
            {
                title: 'ชื่อสินค้า',
                dataIndex: 'item.name',
                key: 'item.name',
                sorter: true,
                render: (text, row) => (
                    <span>{row.item.name}</span>
                )
            },
            {

                title: 'คำอธิบาย',
                dataIndex: 'description',
                key: 'description',
                sorter: true
            },
            {
                title: 'ประเภท',
                dataIndex: 'type',
                key: 'type',
                render: (text, row) => (
                    row.type === "ADD" ?
                    <PlusCircleFilled style={{fontSize: 20, color: '#28a745'}}/> : <MinusCircleFilled style={{fontSize: 20, color: '#ff4d4f'}}/>
                ),
                sorter: true
            },
            {
                title: 'จำนวน',
                dataIndex: 'amount',
                key: 'amount',
                sorter: true,
                render: (text, row) => (
                    <span>{text} {row.item.unit}</span>
                )
            },
            {
                title: 'คลัง',
                dataIndex: 'item.current_stock',
                key: 'item.current_stock',
                sorter: true,
                render: (text, row) => (
                    <span style={row.item.current_stock <= 5  ? { color: 'red' } : {}}>{row.item.current_stock} {row.item.unit}</span>
                )
            },
            {
                title: 'เวลา',
                dataIndex: 'stock_change_at',
                key: 'stock_change_at',
                render: (text, row) => moment(text).format("DD/MM/YYYY HH:mm"),
                sorter: true
            },
            {
                title: 'สร้างโดย',
                dataIndex: 'created_by',
                key: 'created_by',
                render: (text, row) => `${row.created_by.first_name} ${row.created_by.last_name}`,
                sorter: true
            }
        ]

        return (
            <div>
                <Row>
                    <Col span={10} offset={8}>
                        <DatePicker onChange={this.onDateChange} defaultValue={selectedDate} style={{width: "100%"}}/>
                    </Col>
                    <Col span={6}>
                        <Button
                            style={{ float: 'right', marginRight: 5 }}
                            type="danger"
                            icon={<MinusOutlined />}
                            onClick={() => this.onCreateStockChangeClicked("SUB")}
                        />
                        <Button
                            style={{ float: 'right', marginRight: 5 }}
                            type="success"
                            icon={<PlusOutlined />}
                            onClick={() => this.onCreateStockChangeClicked("ADD")}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={24}>
                        <Table
                            rowKey={'id'}
                            columns={tableColumns}
                            dataSource={data}
                            bordered
                            locale={{ filterConfirm: 'OK', filterReset: 'รีเซ็ต', emptyText: 'ไม่พบข้อมูล' }}
                            pagination={{ current: page, pageSize: pageSize, total: total, showSizeChanger: true, pageSizeOptions: ['50', '100', '200', '500'] }}
                            loading={dataLoading}
                            onChange={this.onTableChange}
                        />
                    </Col>
                </Row>

                <StockReportFormModal
                    visible={formModalVislble}
                    setVisible={this.setFormModalVisible}
                    reloadAfterCreate={this.reloadAfterCreate}
                    mode={stockChangeMode}
                    onRef={ref => (this.formModal = ref)}
                    selectedDate={selectedDate}
                />
            </div>
        );
    }
}

export default withRouter(StockReportList);