import React, { Component } from "react";
import { Modal, Form, Input, Row, Col, Button, Select, Table } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { createOrder, updateOrder } from "../../services/order";
import { getOrderItemChoices } from "../../services/orderItems";
import { makeRandomId } from "../../utils";

const { Option } = Select;
const freeItemChoice = {
  id: 0,
  name: "อื่นๆ (ระบุเอง)",
  price: 0,
  unit: "หน่วย",
  type: "ITEM",
};
class OrderFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      itemChoices: [],
      addItemSelectId: undefined,
      addItemAmount: 1,
      sumPrice: 0,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onItemSearch = this.onItemSearch.bind(this);
    this.onAddItemSelected = this.onAddItemSelected.bind(this);
    this.onAddItemButtonClicked = this.onAddItemButtonClicked.bind(this);
    this.onAddItemAmountChange = this.onAddItemAmountChange.bind(this);
    this.prepareForm = this.prepareForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.onRowAmountChange = this.onRowAmountChange.bind(this);
    this.onRowNameChange = this.onRowNameChange.bind(this);
    this.formRef = React.createRef();
  }

  async onFormSubmit() {
    const { items } = this.state;
    const {
      data,
      editing,
      setVisible,
      reloadData,
      reloadAfterCreate,
      orderType,
    } = this.props;
    let values;
    let passed = true;
    try {
      values = await this.formRef.current.validateFields();
    } catch (err) {
      values = err.values;
      passed = false;
    }

    values["order_items"] = items.map((item) => {
      const key_name = item.type === "MEAL" ? "meal_id" : "item_id";
      return {
        [key_name]: item.id,
        amount: item.amount,
        type: item.type,
      };
    });

    values["type"] = orderType;

    if (passed) {
      if (!editing) {
        await createOrder(values);
        reloadAfterCreate();
      } else {
        await updateOrder(data["id"], values);
        reloadData();
      }
      setVisible(false);
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  async onItemSearch(val) {
    const { orderType } = this.props;
    const result = await getOrderItemChoices(val, {}, 1, 50, "name", "ASC");
    const choices =
      orderType === "BUY"
        ? [freeItemChoice, ...result.data.results]
        : result.data.results;
    this.setState({
      itemChoices: choices,
    });
  }

  async prepareForm() {
    const { data, editing } = this.props;
    let orderItems;
    if (editing) {
      orderItems = data["order_items"].map((item) => ({
        rowKey: item.id,
        id: item.id,
        name: item.name,
        amount: item.amount,
        pricePerUnit: item.price_per_unit,
        price: parseInt(item.price),
        unit: item.unit,
      }));
      this.setState({
        items: orderItems,
      });
    }
    await this.onItemSearch("");
  }

  onAddItemSelected(e, val) {
    this.setState({
      addItemSelectId: e,
    });
  }

  onAddItemButtonClicked() {
    const { addItemSelectId, itemChoices, items, addItemAmount } = this.state;
    let addAmount = addItemAmount || 1;
    for (let i = 0; i < itemChoices.length; i++) {
      if (itemChoices[i].uid === addItemSelectId) {
        let itemExist = false;
        for (let j = 0; j < items.length; j++) {
          if (itemChoices[i].uid === items[j].uid) {
            itemExist = true;
            items[j].amount += addAmount;
            items[j].price = items[j].amount * items[j].pricePerUnit;
          }
        }
        if (!itemExist) {
          let name = itemChoices[i].name;
          let rowKey = itemChoices[i].uid;
          if (itemChoices[i].uid === 0) {
            // Free orderItem, generate random id
            name = "";
            rowKey = `free_${makeRandomId(20)}`;
          }
          items.push({
            rowKey: rowKey,
            uid: itemChoices[i].uid,
            id: itemChoices[i].id,
            name: name,
            amount: addAmount,
            pricePerUnit: itemChoices[i].price,
            price: itemChoices[i].price * addAmount,
            type: itemChoices[i].type,
            unit: itemChoices[i].unit,
          });
        }
      }
    }

    // Sum item price
    let sumPrice = 0;
    for (let i = 0; i < items.length; i++) {
      sumPrice += items[i].price;
    }

    this.setState(
      {
        items: items,
        sumPrice: sumPrice,
      },
      () => {
        this.setState({
          addItemAmount: 1,
        });
      }
    );
  }

  onAddItemAmountChange(e) {
    this.setState({
      addItemAmount: parseInt(e.target.value),
    });
  }

  onRowAmountChange(e, row, overrideEmpty) {
    const { items } = this.state;
    let amount = overrideEmpty ? parseInt(e.target.value) || 1 : parseInt(e.target.value);
    for (let i = 0; i < items.length; i++) {
      if (row.rowKey === items[i].rowKey) {
        items[i].amount = amount;
        items[i].price = items[i].amount * items[i].pricePerUnit;
      }
    }
    // Sum item price
    let sumPrice = 0;
    for (let i = 0; i < items.length; i++) {
      sumPrice += items[i].price;
    }
    this.setState({
      items: items,
      sumPrice,
    });
  }

  onRowNameChange(e, row) {
    const { items } = this.state;
    for (let i = 0; i < items.length; i++) {
      if (row.rowKey === items[i].rowKey) {
        items[i].name = e.target.value;
      }
    }
    this.setState({
      items: items,
    });
  }

  onRowDeleteClick(row) {
    const { items } = this.state;
    for (let i = 0; i < items.length; i++) {
      if (row.rowKey === items[i].rowKey) {
        items.splice(i, 1);
        break;
      }
    }
    this.setState({
      items: items,
    });
  }

  clearForm() {
    // Clear state on close
    this.setState({
      items: [],
      itemChoices: [],
      addItemSelectId: undefined,
      addItemAmount: 1,
    });
  }

  render() {
    const { editing, visible, setVisible, data } = this.props;
    const { itemChoices, items, addItemAmount, sumPrice } = this.state;

    let itemTableColumns = [
      {
        title: "สินค้า",
        dataIndex: "name",
        key: "name",
        render: (text, row) =>
          !editing && row.id === 0 ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Input
                value={text}
                onChange={(e) => this.onRowNameChange(e, row)}
              />
            </div>
          ) : (
            <span>{text}</span>
          ),
      },
      {
        title: "ราคาต่อหน่วย",
        dataIndex: "pricePerUnit",
        key: "pricePerUnit",
      },
      {
        title: "จำนวน",
        dataIndex: "amount",
        key: "amount",
        render: (text, row) =>
          !editing ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Input
                value={row.amount}
                type="number"
                onChange={(e) => this.onRowAmountChange(e, row, false)}
                onBlur={(e) => this.onRowAmountChange(e, row, true)}
                style={{ marginRight: 10 }}
              />{" "}
              <span>{row.unit}</span>
            </div>
          ) : (
            <span>
              {text} {row.unit}
            </span>
          ),
      },
      {
        title: "ราคา",
        dataIndex: "price",
        key: "price",
        render: (text, row) => text.toFixed(2),
      },
      {
        title: "ตัวเลือก",
        dataIndex: "action",
        key: "action",
        render: (text, row) => (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              type="danger"
              shape="circle"
              size="small"
              icon={<DeleteOutlined />}
              onClick={() => this.onRowDeleteClick(row)}
              style={{ marginRight: 5 }}
              disabled={editing}
            />
          </div>
        ),
      },
    ];

    return (
      <Modal
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={this.onFormSubmit}
        width={"70vw"}
        style={{ maxWidth: 800, minWidth: 600 }}
        title={
          editing ? `รายละเอียดคำสั่งซื้อ ${data.order_number}` : "เพิ่มรายการ"
        }
        okText={editing ? "รายละเอียด" : "เพิ่ม"}
        afterClose={this.clearForm}
        cancelText={"ปิด"}
        keyboard={false}
        maskClosable={false}
        destroyOnClose={true}
        okButtonProps={editing ? { style: { display: "none" } } : {}}
      >
        <Form ref={this.formRef} layout={"vertical"} initialValues={data}>
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                hasFeedback
                label="หมายเหตุ"
                name="description"
                rules={[]}
              >
                <Input disabled={editing} />
              </Form.Item>
            </Col>
          </Row>

          {!editing ? (
            <Row gutter={12} style={{ marginBottom: 25 }}>
              <Col span={12}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="กรุณาเลือกสินค้า"
                  optionFilterProp="children"
                  onSearch={this.onItemSearch}
                  disabled={editing}
                  onSelect={this.onAddItemSelected}
                >
                  {itemChoices.map((cc) => (
                    <Option key={cc.uid} value={cc.uid}>
                      {cc.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={6}>
                <Input
                  type="number"
                  min={1}
                  onChange={this.onAddItemAmountChange}
                  value={addItemAmount}
                />
              </Col>
              <Col span={6}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={this.onAddItemButtonClicked}
                />
              </Col>
            </Row>
          ) : null}

          <Row gutter={12}>
            <Col span={24}>
              <Table
                rowKey={"rowKey"}
                columns={itemTableColumns}
                dataSource={[...items]}
                bordered
                locale={{
                  filterConfirm: "OK",
                  filterReset: "รีเซ็ต",
                  emptyText: "ไม่พบข้อมูล",
                }}
                pagination={{ hideOnSinglePage: true }}
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <div
                style={{
                  marginTop: 15,
                  fontSize: 18,
                  display: "flex",
                  flex: 1,
                  justifyContent: "right",
                }}
              >
                ราคารวม: {editing ? data.total_price : sumPrice.toFixed(2)} บาท
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default OrderFormModal;
