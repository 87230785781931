import React, { Component } from "react";
import { Modal, Form, Input, Row, Col, Radio } from "antd";
import { signTimeSheet } from "../../services/timeSheet";

class TimeSheetFormModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disableOkButton: false,
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.prepareForm = this.prepareForm.bind(this);
        this.formRef = React.createRef();
    }

    async onFormSubmit() {
        const { data, editing, setVisible, reloadData, reloadAfterCreate, selectedEmployee } = this.props;
        await this.setState({
            disableOkButton: true
        })
        let values = {};
        // let passed = true;
        // try {
        //     values = await this.formRef.current.validateFields()
        // }
        // catch (err) {
        //     values = err.values
        //     passed = false
        // }

        // if (passed) {
            try {
                if (!editing) {
                    values["employee"] = selectedEmployee
                    await signTimeSheet(values)
                    reloadAfterCreate();
                }
                else {
                    // await updateTimeSheet(data['id'], values)
                    reloadData();
                }
                setVisible(false);

            }
            catch (err) {
            }
            finally {
                this.setState({
                    disableOkButton: false
                })
            }
        // }
        // else {
        //     this.setState({
        //         disableOkButton: false
        //     })
        // }

    };

    componentDidMount() {
        this.props.onRef(this);
    }

    async prepareForm() {

    }

    render() {
        const { editing, visible, setVisible, data } = this.props;
        const { disableOkButton } = this.state;
        return (
            <Modal
                visible={visible}
                onCancel={() => { setVisible(false) }}
                onOk={this.onFormSubmit}
                title={editing ? "แก้ไขรายการ" : "ลงเวลางาน"}
                okText={editing ? "แก้ไข" : "บันทึก"}
                cancelText={"ปิด"}
                keyboard={false}
                maskClosable={false}
                destroyOnClose={true}
                okButtonProps={{disabled: disableOkButton}}
            >
                <p>ต้องการลงเวลางานใช่หรือไม่?</p>
                {/* <Form
                    ref={this.formRef}
                    layout={'vertical'}
                    initialValues={data}
                > */}
                    
                    {/* <Row gutter={12}>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                hasFeedback
                                label="รหัสผ่านประจำตัว"
                                name="passcode"
                                rules={[
                                    {
                                        required: true,
                                        message: "กรุณาใส่รหัสผ่าน",
                                    }
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row> */}
                    
                    {/* <Row gutter={12}>
                        <Col span={24}>
                            <Form.Item
                                hasFeedback
                                label="รายละเอียด"
                                name="description"
                                rules={[]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row> */}
                {/* </Form> */}
            </Modal>
        );
    }
}

export default TimeSheetFormModal;