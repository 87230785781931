import React, { Component } from "react";
import { Modal, Form, Input, Row, Col, Select } from "antd";
import { createBooking, updateBooking } from "../../services/booking";
import moment from 'moment';

const { Option } = Select;

const normalRoomPrices = [
    {
        "price": "120",
        "description": "ต่อเวลา"
    },
    {
        "price": "240",
        "description": "ชั่วคราว 3 ชม."
    },
    {
        "price": "350",
        "description": "ชั่วคราว 7 ชม."
    },
    {
        "price": "450",
        "description": "ค้างคืน"
    }
]

const smallBungalowPrices = [
    {
        "price": "150",
        "description": "ต่อเวลา"
    },
    {
        "price": "300",
        "description": "ชั่วคราว 3 ชม."
    },
    {
        "price": "400",
        "description": "ชั่วคราว 7 ชม."
    },
    {
        "price": "650",
        "description": "ค้างคืน"
    }
]

const bigBungalowPrices = [
    {
        "price": "200",
        "description": "ต่อเวลา"
    },
    {
        "price": "350",
        "description": "ชั่วคราว 3 ชม."
    },
    {
        "price": "450",
        "description": "ชั่วคราว 7 ชม."
    },
    {
        "price": "700",
        "description": "ค้างคืน"
    }
]

class BookingFormModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            roomPrefixes: [
                "A",
                "B",
                "C",
                "บ้านน้ำทิพย์",
                "บ้านออมสิน",
                "บ้านออมทรัพย์"
            ],
            roomNumbers: [
                "1", "2", "3", "4", "5", "6", "7", "8"
            ],
            bookingPrices: normalRoomPrices,
            selectedPrefix: null,
            disableOkButton: false,
            requireRoomNumber: false,
            allow7Hour: true
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.isNormalRoom = this.isNormalRoom.bind(this);
        this.prepareForm = this.prepareForm.bind(this);
        this.onRoomPrefixSelected = this.onRoomPrefixSelected.bind(this);
        this.formRef = React.createRef();
    }

    async onFormSubmit() {
        const { data, editing, setVisible, reloadData, reloadAfterCreate } = this.props;
        await this.setState({
            disableOkButton: true
        })
        let values;
        let passed = true;
        try {
            values = await this.formRef.current.validateFields()
        }
        catch (err) {
            values = err.values
            passed = false
        }

        if (this.isNormalRoom(values['room'])){
            values['room'] = values['room'] + values['room_number']
        }

        if (passed) {
            try {
                if (!editing) {
                    await createBooking(values)
                    reloadAfterCreate();
                }
                else {
                    await updateBooking(data['id'], values)
                    reloadData();
                }
                setVisible(false);

            }
            catch (err) {
            }
            finally {
                this.setState({
                    disableOkButton: false,
                    allow7Hour: true
                })
            }
        }
        else {
            this.setState({
                disableOkButton: false,
                allow7Hour: true
            })
        }

    };

    componentDidMount() {
        this.props.onRef(this);
    }

    isNormalRoom(selectedPrefix) {
        return ["A", "B", "C"].includes(selectedPrefix)
    }

    onRoomPrefixSelected(val, resetFields) {
        // Set room prefix & price for the room type
        let requireRoomNumber = false
        let bookingPrices;
        if (this.isNormalRoom(val)) {
            requireRoomNumber = true
            bookingPrices = JSON.parse(JSON.stringify(normalRoomPrices))
        }
        else {
            // Check if big or small bungalow
            if (val === "บ้านน้ำทิพย์"){
                bookingPrices = JSON.parse(JSON.stringify(bigBungalowPrices))
            }
            else {
                bookingPrices = JSON.parse(JSON.stringify(smallBungalowPrices))
            }
        }
        // Check if 7 hour booking is allowed in current time, if not then remove option
        const now = moment(new Date())
        const currentHour = now.hour()
        let allowed = currentHour >= 6 && currentHour <= 16
        if (!allowed){
            bookingPrices = bookingPrices.filter(function( obj ) {
                return obj.description !== "ชั่วคราว 7 ชม."
            });
        }
        this.setState({
            selectedPrefix: val,
            requireRoomNumber,
            bookingPrices,
            allow7Hour: allowed
        }, ()=>{
            if (resetFields){
                this.formRef.current.setFieldsValue({ room_number: undefined, price: undefined });
            }
        })
    }

    async prepareForm() {
        const { data } = this.props
        this.onRoomPrefixSelected(data['room'], false)
    }

    render() {
        const { editing, visible, setVisible, data } = this.props;
        const { roomPrefixes, roomNumbers, bookingPrices, disableOkButton, requireRoomNumber, allow7Hour } = this.state;
        let priceLabel = <p style={{fontSize:20, marginBottom:0}}>ราคา</p>
        if (!allow7Hour) {
            priceLabel = <p style={{fontSize:20, marginBottom:0}}>ราคา (ไม่สามารถเปิด 7 ชม. ในช่วงเวลา 17:00-6:00)</p>
        }
        return (
            <Modal
                visible={visible}
                onCancel={() => { setVisible(false) }}
                onOk={this.onFormSubmit}
                title={editing ? <p style={{fontSize: 22, marginBottom: 0}}>แก้ไขรายการ</p> : <p style={{fontSize: 22, marginBottom: 0}}>เปิดห้อง</p>}
                okText={editing ? "แก้ไข" : "เพิ่ม"}
                cancelText={"ปิด"}
                keyboard={false}
                maskClosable={false}
                destroyOnClose={true}
                okButtonProps={{disabled: disableOkButton, size: 'large'}}
                cancelButtonProps={{size: 'large'}}
            >
                <Form
                    ref={this.formRef}
                    layout={'vertical'}
                    initialValues={data}
                >
                    <Row gutter={12}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                hasFeedback
                                label={ <p style={{fontSize:20, marginBottom:0}}>ห้อง</p> }
                                name="room"
                                rules={[
                                    {
                                        required: true,
                                        message: "กรุณาใส่เลขห้อง"
                                    }
                                ]}>
                                <Select
                                    showSearch
                                    style={{ width: '100%', fontSize: 18 }}
                                    placeholder="กรุณาเลือกห้อง"
                                    optionFilterProp="children"
                                    onSelect={e => this.onRoomPrefixSelected(e, true)}
                                >
                                    {roomPrefixes.map(rpf =>
                                        <Option style={{fontSize: 18}} key={rpf} value={rpf}>{rpf}</Option>
                                    )}
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                hasFeedback
                                label={ <p style={{fontSize:20, marginBottom:0}}>หมายเลข</p> }
                                name="room_number"
                                rules={[
                                    {
                                        required: requireRoomNumber,
                                        message: "กรุณาใส่เลขห้อง"
                                    }
                                ]}>
                                <Select
                                    showSearch
                                    style={{ width: '100%', fontSize: 18 }}
                                    placeholder="กรุณาเลือกหมายเลขห้อง"
                                    optionFilterProp="children"
                                    disabled={!requireRoomNumber}
                                >
                                    {roomNumbers.map(rn =>
                                        <Option style={{fontSize: 18}} key={rn} value={rn}>{rn}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                hasFeedback
                                label={priceLabel}
                                name="price"
                                rules={[
                                    {
                                        required: true,
                                        message: "กรุณาเลือกราคา"
                                    }
                                ]}>
                                <Select
                                    showSearch
                                    style={{ width: '100%', fontSize: 18 }}
                                    placeholder="กรุณาเลือกราคา"
                                    optionFilterProp="children"
                                >
                                    {bookingPrices.map((rn, ind) =>
                                        <Option style={{fontSize: 18}} key={ind} value={rn.price}>{rn.price} ({rn.description})</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={24}>
                            <Form.Item
                                hasFeedback
                                label={ <p style={{fontSize:20, marginBottom:0}}>หมายเหตุอื่นๆ</p> }
                                name="remark"
                                rules={[]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

export default BookingFormModal;