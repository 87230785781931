import React, { Component } from "react";
import { Modal, Form, Input, Row, Col, Radio } from "antd";
import { createUser, updateUser } from "../../services/user";

class UserFormModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmPasswordValidateStatus: undefined,
            confirmPasswordValidateMessage: undefined
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onFormDataChanged = this.onFormDataChanged.bind(this);
        this.formRef = React.createRef();
    }

    async onFormSubmit() {
        const { data, editing, setVisible, reloadData, reloadAfterCreate } = this.props;
        let values;
        let passed = true;
        try {
            values = await this.formRef.current.validateFields()
        }
        catch (err) {
            values = err.values
            passed = false
        }

        if (passed) {
            if (!editing) {
                await createUser(values)
                reloadAfterCreate();
            }
            else {
                await updateUser(data['id'], values)
                reloadData();
            }
            setVisible(false);
        }

    };

    onFormDataChanged(changed, all) {
        if (changed['password'] !== undefined) {
            if (changed['password'] !== all['confirm_password']) {
                this.setState({
                    confirmPasswordValidateStatus: "error",
                    confirmPasswordValidateMessage: "รหัสผ่านใหม่ไม่เหมือนกับช่องด้านบน"
                })
            }
            else {
                this.setState({
                    confirmPasswordValidateStatus: "success",
                    confirmPasswordValidateMessage: undefined
                })
            }

        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }


    render() {
        const { editing, visible, setVisible, data } = this.props;
        const { confirmPasswordValidateMessage, confirmPasswordValidateStatus } = this.state;
        const roleOptions = [
            { name: "Admin", value: "ADMIN" },
            { name: "Employee", value: "EMPLOYEE" }
        ]
        return (
            <Modal
                visible={visible}
                onCancel={() => { setVisible(false) }}
                onOk={this.onFormSubmit}
                title={editing ? "แก้ไขรายการ" : "เพิ่มรายการ"}
                okText={editing ? "แก้ไข" : "เพิ่ม"}
                afterClose={this.clearForm}
                cancelText={"ปิด"}
                keyboard={false}
                maskClosable={false}
                destroyOnClose={true}
            >
                <Form
                    ref={this.formRef}
                    layout={'vertical'}
                    initialValues={data}
                    onValuesChange={this.onFormDataChanged}
                >
                    <Row gutter={12}>
                        <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                hasFeedback
                                label="ชื่อผู้ใช้"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: "กรุณาใส่ชื่อผู้ใช้"
                                    }
                                ]}>
                                <Input disabled={editing} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {!editing &&
                        <Row gutter={12}>
                            <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <Form.Item
                                    hasFeedback
                                    label="รหัสผ่าน"
                                    name="password"
                                    rules={[{ required: true, message: 'กรุณาใส่รหัสผ่านใหม่' }, { min: 8, message: 'ความยาวรหัสผ่านต้องมีจำนวน 8-12 ตัวอักษร' }, { max: 12, message: 'ความยาวรหัสผ่านต้องมีจำนวน 8-12 ตัวอักษร' }]}
                                >
                                    <Input type="password" />
                                </Form.Item>
                            </Col>
                            <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <Form.Item
                                    hasFeedback
                                    label="ยืนยันรหัสผ่าน"
                                    name="confirm_password"
                                    help={confirmPasswordValidateMessage}
                                    validateStatus={confirmPasswordValidateStatus}
                                >
                                    <Input type="password" />
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                    <Row gutter={12}>
                        <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                hasFeedback
                                label="ชื่อ"
                                name="first_name"
                                rules={[
                                    {
                                        required: true,
                                        message: "กรุณาใส่ชื่อ"
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                hasFeedback
                                label="นามสกุล"
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                        message: "กรุณาใส่นามสกุล"
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Form.Item
                            label="Role"
                            name="role"
                            rules={[
                                {
                                    required: true,
                                    message: "กรุณาเลือก Role"
                                }
                            ]}
                        >
                            <Radio.Group defaultValue={"EMPLOYEE"}>
                                {roleOptions.map(ro => <Radio key={ro.value} value={ro.value}>{ro.name}</Radio>)}
                            </Radio.Group>
                        </Form.Item>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

export default UserFormModal;