import axios from "axios";
import { convertSortDir } from "../utils";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const getUsers = async (q, filter_params, page, page_size, sort_by, sort_dir) => {

    let params = filter_params
    params['page'] = page
    params['page_size'] = page_size
    params['q'] = q
    params['sort_by'] = sort_by
    params['sort_dir'] = convertSortDir(sort_dir)

    const res = await axios.get(BACKEND_URL+'/users', {
        params,
        withCredentials: true
    });
    return res;
}

export const createUser = async (createObj) => {
    createObj  = Object.fromEntries(Object.entries(createObj).filter(([_, v]) => v !== undefined));
    const res = await axios.post(BACKEND_URL+'/users/register',
    createObj,
    {
        withCredentials: true,
        successMessage: "User ได้ถูกสร้างแล้ว"
    });
    return res;
}

export const updateUser = async (id, updateObj) => {
    const res = await axios.put(BACKEND_URL+`/users/${id}`,
    updateObj,
    {
        withCredentials: true,
        successMessage: "User ได้ถูกแก้ไขแล้ว"
    });
    return res;
}

export const deleteUser = async (id) => {
    const res = await axios.delete(BACKEND_URL+`/users/${id}`,
    {
        withCredentials: true,
        successMessage: "User ได้ถูก Deactivate แล้ว"
    });
    return res;
}

export const restoreUser = async (id) => {
    const res = await axios.post(BACKEND_URL+`/users/${id}/restore`,
    {},
    {
        withCredentials: true,
        successMessage: "User ได้ถูก Activate แล้ว"
    });
    return res;
}