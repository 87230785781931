import React, { Component } from "react";
import {
  Layout,
  Menu,
  Breadcrumb,
  Dropdown,
  Button,
  Avatar,
  Image,
} from "antd";
import {
  UserOutlined,
  ScheduleOutlined,
  CoffeeOutlined,
  DollarOutlined,
  HomeOutlined,
  AuditOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { notification } from "antd";
import { capitalizeFirst } from "../utils";
import "./MenuLayout.css";
import { connect } from "react-redux";
import { logout_profile } from "../redux/actions/profile";

const { Header, Content, Footer } = Layout;

const notiMethods = ["post", "put", "delete"];

class MenuLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onMenuClicked = this.onMenuClicked.bind(this);
    this.onLogoutClicked = this.onLogoutClicked.bind(this);
  }

  onMenuClicked(menuName) {
    const { history } = this.props;
    history.push(`/${menuName}`);
  }

  async onLogoutClicked() {
    const { history, dispatch } = this.props;
    dispatch(logout_profile());
    history.push(`/login`);
  }

  async componentWillMount() {
    const { history, profile } = this.props;

    axios.interceptors.request.use(
      function (config) {
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        // Overide message if role is ADMIN
        let messageTitle = "เรียบร้อย!";
        let messageDescription =
          response.config.successMessage || "ทำรายการสำเร็จลุล่วง";

        if (
          response.status === 200 &&
          notiMethods.includes(response.config.method)
        ) {
          notification.destroy();
          notification["success"]({
            message: messageTitle,
            description: messageDescription,
          });
        }
        return response;
      },
      function (error) {
        if (error.response.status === 403) {
          history.push("/login");
        } else {
          notification.destroy();
          notification["error"]({
            message: `เกิดข้อผิดพลาด (${error.response.status})`,
            description: error.response.data.error,
          });
        }

        return Promise.reject(error); // dont' throw here!!! each function need to handle 500 individually
      }
    );
  }

  render() {
    const { displayComponent, selectedMenu, profile } = this.props;

    const profileMenu = (
      <Menu>
        <Menu.Item>
          <a rel="noopener noreferrer" href="./#/profile">
            Profile
          </a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={this.onLogoutClicked}>Logout</a>
        </Menu.Item>
      </Menu>
    );

    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <div className="logo" />
          <Menu theme="dark" mode="horizontal" selectedKeys={[selectedMenu]}>
            <Menu.Item
              key="avatar"
              style={{ position: "absolute", top: 0, right: 0 }}
            >
              <Dropdown overlay={profileMenu} placement="bottomCenter">
                <Button className="user-info-button">
                  {" "}
                  <Avatar
                    icon={<UserOutlined />}
                    style={{ marginRight: 5 }}
                  />{" "}
                  <span
                    style={{
                      color:
                        profile.profile.role === "ADMIN" ? "gold" : "#ffffff",
                    }}
                  >
                    {" "}
                    {profile.profile.first_name} {profile.profile.last_name}{" "}
                  </span>
                </Button>
              </Dropdown>
            </Menu.Item>

            <Menu.Item
              key="bookings"
              onClick={() => this.onMenuClicked("bookings")}
            >
              <ScheduleOutlined style={{ marginRight: 5 }} />
              เปิดห้อง
            </Menu.Item>
            <Menu.Item
              key="sale_orders"
              onClick={() => this.onMenuClicked("sale_orders")}
            >
              <DollarOutlined style={{ marginRight: 5 }} />
              ขายสินค้า
            </Menu.Item>
            {/* <Menu.Item key="purchase_orders" onClick={() => this.onMenuClicked("purchase_orders")}>ซื้อสินค้า</Menu.Item> */}
            <Menu.Item key="meals" onClick={() => this.onMenuClicked("meals")}>
              {" "}
              <CoffeeOutlined style={{ marginRight: 5 }} /> รายการอาหาร
            </Menu.Item>
            {profile.profile.role === "ADMIN" ? (
              <Menu.Item
                key="room_report"
                onClick={() => this.onMenuClicked("room_report")}
              >
                <HomeOutlined style={{ marginRight: 5 }} />
                รายการห้อง
              </Menu.Item>
            ) : null}
            {/* { profile.profile.role === "ADMIN" ? <Menu.Item key="employees" onClick={() => this.onMenuClicked("employees")}>พนักงาน</Menu.Item> : null } */}
            {profile.profile.role === "ADMIN" ? (
              <Menu.Item
                key="stock_report"
                onClick={() => this.onMenuClicked("stock_report")}
              >
                <AuditOutlined style={{ marginRight: 5 }} />
                รายงานสินค้ารายวัน
              </Menu.Item>
            ) : null}
            {profile.profile.role === "ADMIN" ? (
              <Menu.Item
                key="stocks"
                onClick={() => this.onMenuClicked("stocks")}
              >
                <AppstoreOutlined style={{ marginRight: 5 }} />
                คลังสินค้า
              </Menu.Item>
            ) : null}
            {profile.profile.role === "ADMIN" ? (
              <Menu.Item
                key="users"
                onClick={() => this.onMenuClicked("users")}
              >
                <UserOutlined style={{ marginRight: 5 }} />
                จัดการผู้ใช้
              </Menu.Item>
            ) : null}
            {/* <Menu.Item key="time_sheets" onClick={() => this.onMenuClicked("time_sheets")}>ลงเวลางาน</Menu.Item> */}
          </Menu>
        </Header>
        <Content
          className="site-layout"
          style={{ padding: "0 50px", marginTop: 64 }}
        >
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item> {capitalizeFirst(selectedMenu)} </Breadcrumb.Item>
          </Breadcrumb>
          <div
            className="site-layout-background"
            style={{ padding: 24, height: "100%" }}
          >
            {displayComponent}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          {profile.profile.role === "ADMIN"
            ? "©2023 Powered by The Rotten Meat"
            : "Thip U Thong System ©2023 Created by kenganan17"}
          <Image
            height={25}
            width={25}
            style={{ marginTop: 5, marginLeft: 5 }}
            src="/rotten-meat.png"
            preview={false}
          />
        </Footer>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  };
}

export default withRouter(connect(mapStateToProps)(MenuLayout));
