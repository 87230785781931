import axios from "axios";
import { convertSortDir } from "../utils";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const getBookings = async (q, filter_params, page, page_size, sort_by, sort_dir) => {

    let params = filter_params
    params['page'] = page
    params['page_size'] = page_size
    params['q'] = q
    params['sort_by'] = sort_by
    params['sort_dir'] = convertSortDir(sort_dir)

    const res = await axios.get(BACKEND_URL+'/bookings', {
        params,
        withCredentials: true
    });
    return res;
}

export const createBooking = async (createObj) => {
    createObj  = Object.fromEntries(Object.entries(createObj).filter(([_, v]) => v !== undefined));
    const res = await axios.post(BACKEND_URL+'/bookings',
    createObj,
    {
        withCredentials: true,
        successMessage: "Booking ได้ถูกสร้างแล้ว"
    });
    return res;
}

export const updateBooking = async (id, updateObj) => {
    const res = await axios.put(BACKEND_URL+`/bookings/${id}`,
    updateObj,
    {
        withCredentials: true,
        successMessage: "Booking ได้ถูกแก้ไขแล้ว"
    });
    return res;
}

export const deleteBooking = async (id) => {
    const res = await axios.delete(BACKEND_URL+`/bookings/${id}`,
    {
        withCredentials: true,
        successMessage: "Booking ได้ถูกลบแล้ว"
    });
    return res;
}

export const checkoutBooking = async (id) => {
    const res = await axios.post(BACKEND_URL+`/bookings/${id}/checkout`, {},
    {
        withCredentials: true,
        successMessage: `เช็คเอาท์เรียบร้อยแล้ว`
    });
    return res;
}
