import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const login = async (username, password) => {
    const res = await axios.post(BACKEND_URL + '/login',
        {
            username,
            password
        },
        {
            withCredentials: true,
        });
    return res;
}

export const logout = async () => {
    const res = await axios.post(BACKEND_URL + '/logout',
        {},
        {
            withCredentials: true,
        });
    return res;
}

export const getProfile = async () => {
    const res = await axios.get(BACKEND_URL + '/profile',
        {
            withCredentials: true,
        });
    return res;
}

export const changePassword = async (old_password, new_password) => {
    const res = await axios.post(BACKEND_URL + '/change_password',
        {
            old_password,
            new_password
        },
        {
            withCredentials: true,
            successMessage: "รหัสผ่านได้ถูกเปลี่ยนแล้ว"
        });
    return res;
}