import React, { Component } from 'react';
import { Tabs, Card, Form, Input, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { changePassword } from '../services/api';

const { TabPane } = Tabs;

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmPasswordValidateStatus: undefined,
            confirmPasswordValidateMessage: undefined
        }
        this.onPasswordDataChanged = this.onPasswordDataChanged.bind(this)
        this.onProfileDataChanged = this.onProfileDataChanged.bind(this)
        this.onChangePasswordSubmit = this.onChangePasswordSubmit.bind(this)
    }

    onPasswordDataChanged(key, value) {
        this.setState({
            [key]: value
        })
    }

    onProfileDataChanged(changed, all) {
        if (changed['confirm_new_password'] !== undefined) {
            if (changed['confirm_new_password'] !== all['new_password']) {
                this.setState({
                    confirmPasswordValidateStatus: "error",
                    confirmPasswordValidateMessage: "รหัสผ่านใหม่ไม่เหมือนกับช่องด้านบน"
                })
            }
            else {
                this.setState({
                    confirmPasswordValidateStatus: "success",
                    confirmPasswordValidateMessage: undefined
                })
            }
            
        }
    }

    async onChangePasswordSubmit(values) {
        await changePassword(values.old_password, values.new_password)
    };

    render() {
        const { userProfle } = this.props
        const { confirmPasswordValidateStatus, confirmPasswordValidateMessage } = this.state

        return (
            <Card style={{ maxWidth: 600, margin: 'auto' }}>
                <Tabs tabPosition={'left'}>
                    <TabPane tab="รหัสผ่าน" key="1">
                        <h2>เปลี่ยนรหัสผ่าน</h2>
                        <br/>
                        <Form
                            layout={'vertical'}
                            initialValues={{ userProfle }}
                            onValuesChange={this.onProfileDataChanged}
                            onFinish={this.onChangePasswordSubmit}
                        >
                            <Form.Item label="รหัสผ่านเดิม" name="old_password" hasFeedback rules={[{ required: true, message: 'กรุณาใส่รหัสผ่านเดิม' }]}>
                                <Input type="password" />
                            </Form.Item>
                            <Form.Item label="รหัสผ่านใหม่" name="new_password" hasFeedback rules={[{ required: true, message: 'กรุณาใส่รหัสผ่านใหม่' }, { min: 8, message: 'ความยาวรหัสผ่านต้องมีจำนวน 8-12 ตัวอักษร' }, { max: 12, message: 'ความยาวรหัสผ่านต้องมีจำนวน 8-12 ตัวอักษร' }]}>
                                <Input type="password" />
                            </Form.Item>
                            <Form.Item label="ยืนยันรหัสผ่านใหม่" name="confirm_new_password" hasFeedback help={confirmPasswordValidateMessage} validateStatus={confirmPasswordValidateStatus}>
                                <Input type="password" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Save</Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                </Tabs>
            </Card>

        );
    }
}

export default withRouter(Profile);