import React, { Component } from 'react';
import { Table, Input, Row, Col, Checkbox } from 'antd'
import { getRooms } from "../../services/room";
import moment from 'moment';

const { Search } = Input;
const roomNameMap = {
    "BAN_NAMTHIP": "บ้านน้ำทิพย์",
    "BAN_OMSIN": "บ้านออมสิน",
    "BAN_OMSAP": "บ้านออมทรัพย์"
}

class RoomList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            pageSize: 50,
            total: 10,
            sortBy: "created_at",
            sortDir: "ascend",
            q: "",
            dataLoading: false,
            seenRecently: false
        }

        this.getData = this.getData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
        this.renderStatus = this.renderStatus.bind(this);
        this.renderDateTime = this.renderDateTime.bind(this);
        this.onSeenRecentlyCheck = this.onSeenRecentlyCheck.bind(this);
    }

    onPageChange(page, pageSize) {
        this.setState({
            page: page,
            pageSize: pageSize
        }, () => {
            this.getData()
        })
    }

    onPageSizeChange(current, size) {
        this.setState({
            page: current,
            pageSize: size
        }, () => {
            this.getData()
        })
    }

    onTableChange(pagination, filters, sorter) {
        const { page, pageSize, sortBy, sortDir } = this.state
        this.setState({
            page: pagination['current'] || page,
            pageSize: pagination['pageSize'] || pageSize,
            sortBy: sorter['columnKey'] || sortBy,
            sortDir: sorter['order'] || sortDir,
        }, () => {
            this.getData()
        })
    };

    onSearch(value) {
        this.setState({
            q: value,
            page: 1
        }, () => {
            this.getData()
        })
    }

    async getData() {
        const { seenRecently } = this.state
        const filterObj = {}
        if (seenRecently) {
            filterObj["is_seen_recently"] = seenRecently
        }
        this.setState({
            dataLoading: true
        }, async () => {
            const { q, page, pageSize, sortBy, sortDir } = this.state
            let result = await getRooms(q, filterObj, page, pageSize, sortBy, sortDir)
            this.setState({
                data: result.data.rooms,
                total: result.data.total,
                dataLoading: false
            })
        })
    }

    renderStatus(lastSeen) {
        if (!lastSeen){
            return <span>-</span>
        }
        const lastSeenAt = moment(lastSeen)
        const now = moment(new Date())
        const duration = moment.duration(now.diff(lastSeenAt));
        const minutes = duration.asMinutes();
        if (minutes >= 5){
            return <span style={{color: 'red'}}>ปิด</span>
        }
        return <span style={{color: 'green'}}>เปิดใช้งาน</span>
    }

    renderDateTime(dt) {
        if (!dt){
            return "-"
        }
        return moment(dt).format("DD/MM/YYYY HH:mm")
    }

    renderRoomName(name) {
        if (name.length > 3){
            return roomNameMap[name]
        }
        return name
    }

    onSeenRecentlyCheck(e) {
        this.setState({
            seenRecently: e.target.checked
        },() => {
            this.getData()
        })
    }

    componentWillMount() {
        this.getData()
    }

    render() {
        const { data, total, page, pageSize, dataLoading } = this.state

        let tableColumns = [
            {

                title: 'หมายเลขห้อง',
                dataIndex: 'name',
                key: 'name',
                render: (text, row) => this.renderRoomName(text),
                sorter: true
            },
            {
                title: 'สถานะ',
                dataIndex: 'status',
                key: 'status',
                sorter: true,
                render: (text, row) => (
                    <div> {this.renderStatus(row.last_seen)} </div>
                ),
                sorter: true
            },
            {

                title: 'เปิดใช้งานล่าสุด',
                dataIndex: 'last_online',
                key: 'last_online',
                render: (text, row) => this.renderDateTime(text),
                sorter: true
            },
            {

                title: 'รายงานล่าสุด',
                dataIndex: 'last_seen',
                key: 'last_seen',
                render: (text, row) => this.renderDateTime(text),
                sorter: true
            }
        ]

        return (
            <div>
                <Row>
                    <Col span={8} xs={6} sm={6} md={8} lg={8} xl={8} xxl={8}/>
                    <Col span={10} xs={12} sm={12} md={10} lg={10} xl={10} xxl={10}>
                        <Search
                            placeholder="ค้นหาด้วยหมายเลข"
                            onSearch={this.onSearch}
                        />
                    </Col>
                    <Col span={4} offset={2}>
                        <Checkbox onChange={e => this.onSeenRecentlyCheck(e)}>เปิดใช้งาน</Checkbox>
                    </Col>
                    
                </Row>
                <br />
                <Row>
                    <Col span={24}>
                        <Table
                            rowKey={'id'}
                            columns={tableColumns}
                            dataSource={data}
                            bordered
                            locale={{ filterConfirm: 'OK', filterReset: 'รีเซ็ต', emptyText: 'ไม่พบข้อมูล' }}
                            pagination={{ current: page, pageSize: pageSize, total: total, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '50'] }}
                            loading={dataLoading}
                            onChange={this.onTableChange}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
  
export default RoomList;
