import React, { Component } from 'react';
import { Table, Input, Row, Col, Button, Modal } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined, CheckCircleOutlined, CloseCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { getUsers, deleteUser, restoreUser } from "../../services/user";
import UserFormModal from './UserFormModal';

const { Search } = Input;
const confirm = Modal.confirm;

class UserList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            pageSize: 5,
            total: 10,
            sortBy: "created_at",
            sortDir: "descend",
            q: "",
            dataLoading: false,
            formModalVislble: false,
            modalEditing: false,
            selectedData: {}
        }

        this.getData = this.getData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onRowEditClick = this.onRowEditClick.bind(this);
        this.onRowDeleteClick = this.onRowDeleteClick.bind(this);
        this.setFormModalVisible = this.setFormModalVisible.bind(this);
        this.reloadAfterCreate = this.reloadAfterCreate.bind(this);
        this.onRowRestoreClick = this.onRowRestoreClick.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
    }

    onPageChange(page, pageSize) {
        this.setState({
            page: page,
            pageSize: pageSize
        }, () => {
            this.getData()
        })
    }

    onPageSizeChange(current, size) {
        this.setState({
            page: current,
            pageSize: size
        }, () => {
            this.getData()
        })
    }

    onTableChange (pagination, filters, sorter) {
        const { page, pageSize, sortBy, sortDir } = this.state
        this.setState({
            page: pagination['current']|| page,
            pageSize: pagination['pageSize']|| pageSize,
            sortBy: sorter['columnKey'] || sortBy,
            sortDir: sorter['order']|| sortDir,
        }, () => {
            this.getData()
        })
    };

    onSearch(value) {
        this.setState({
            q: value,
            page: 1
        }, () => {
            this.getData()
        })
    }

    async getData() {
        this.setState({
            dataLoading: true
        }, async () => {
            const { q, page, pageSize, sortBy, sortDir } = this.state
            let result = await getUsers(q, {}, page, pageSize, sortBy, sortDir)
            this.setState({
                data: result.data.users,
                total: result.data.total,
                dataLoading: false
            })
        })
    }

    async reloadAfterCreate() {
        this.setState({
            page: 1
        }, () => {
            this.getData()
        })
    }

    onRowEditClick(row) {
        this.setState(
            {
                formModalVislble: true,
                selectedData: row,
                modalEditing: true
            }
        );
    }

    onRowDeleteClick(row, onPageChange, page, pageSize) {
        confirm({
            title: `ต้องการ Deactivate ${row.username} หรือไม่?`,
            async onOk() {
                let response = await deleteUser(row.id);
                if (response.status === 200) {
                    await onPageChange(page, pageSize);
                }
            },
            onCancel() { }

        });
    }

    onRowRestoreClick(row, onPageChange, page, pageSize) {
        confirm({
            title: `ต้องการ Activate ${row.username} หรือไม่?`,
            async onOk() {
                let response = await restoreUser(row.id);
                if (response.status === 200) {
                    await onPageChange(page, pageSize);
                }
            },
            onCancel() { }

        });
    }

    setFormModalVisible(val) {
        this.setState({
            formModalVislble: val,
            selectedData: {}
        })
    }

    componentWillMount() {
        this.getData()
    }

    render() {

        const { data, total, page, pageSize, dataLoading, formModalVislble, selectedData, modalEditing } = this.state

        let tableColumns = [
            {
                title: 'ชื่อผู้ใช้',
                dataIndex: 'username',
                key: 'username',
                sorter: true
            },
            {
                title: 'ชื่อ',
                dataIndex: 'first_name',
                key: 'first_name',
                sorter: true
            },
            {
                title: 'นามสกุล',
                dataIndex: 'last_name',
                key: 'last_name',
                sorter: true
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                sorter: true
            },
            {
                title: 'สถานะ Active',
                dataIndex: 'is_active',
                key: 'is_active',
                sorter: true,
                render: (text, row) => (
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        {text ? <CheckCircleOutlined style={{ color: '#52C41A' }} /> : <CloseCircleOutlined style={{ color: '#FF4D4F' }} />}
                    </div>
                )
            },
            {
                title: 'ตัวเลือก',
                dataIndex: 'action',
                key: 'action',
                render: (text, row) => (
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            type="default"
                            shape="circle"
                            size="small"
                            icon={<EditOutlined />}
                            onClick={() => this.onRowEditClick(row)}
                            style={{ marginRight: 5 }}
                        />
                        <Button
                            type={row.is_active ? 'danger' : 'primary'}
                            shape="circle"
                            size="small"
                            icon={row.is_active ? <DeleteOutlined /> : <RedoOutlined />}
                            onClick={
                                row.is_active ?
                                () => this.onRowDeleteClick(row, this.getData, page, pageSize)
                                : () => this.onRowRestoreClick(row, this.getData, page, pageSize)
                            }
                        />
                    </div>
                )
            }

        ]

        return (
            <div>
                <Row>
                    <Col span={8} xs={6} sm={6} md={8} lg={8} xl={8} xxl={8}/>
                    <Col span={10} xs={12} sm={12} md={10} lg={10} xl={10} xxl={10}>
                        <Search
                            placeholder="ค้นหาด้วย ชื่อผู้ใช้, ชื่อ หรือนามสกุล"
                            onSearch={this.onSearch}
                        />
                    </Col>
                    <Col span={1} offset={5}>
                        <Button
                            style={{ float: 'right' }}
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => this.setState({ formModalVislble: true, modalEditing: false })}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={24}>
                        <Table
                            rowKey={'id'}
                            columns={tableColumns}
                            dataSource={data}
                            bordered
                            locale={{ filterConfirm: 'OK', filterReset: 'รีเซ็ต', emptyText: 'ไม่พบข้อมูล' }}
                            pagination={{ current: page, pageSize: pageSize, total: total, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '50'] }}
                            loading={dataLoading}
                            onChange={this.onTableChange}
                        />
                    </Col>
                </Row>

                <UserFormModal
                    visible={formModalVislble}
                    setVisible={this.setFormModalVisible}
                    reloadData={this.getData}
                    reloadAfterCreate={this.reloadAfterCreate}
                    editing={modalEditing}
                    data={selectedData}
                    onRef={ref => (this.formModal = ref)}
                />
            </div>
        );
    }
}

export default UserList;