import React, { Component } from "react";
import { Modal, Form, Input, Row, Col } from "antd";
import { addStock, subStock } from "../../../services/item";

class StockHistoryFormModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            desDefValue: ""
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.preFillDescription = this.preFillDescription.bind(this);
        this.formRef = React.createRef();
    }

    preFillDescription(mode) {
        const defVal = mode === "ADD" ? "ซื้อเข้า" : "ขายออก"
        this.setState({
            desDefValue: defVal
        })
    }

    async onFormSubmit() {
        const { setVisible, reloadAfterCreate, mode, itemId } = this.props;
        let values;
        let passed = true;
        try {
            values = await this.formRef.current.validateFields()
        }
        catch (err) {
            values = err.values
            passed = false
        }

        if (passed) {
            if (mode === "ADD") {
                await addStock(itemId, values)
            }
            else if (mode === "SUB") {
                await subStock(itemId, values)
            }
            reloadAfterCreate();
            setVisible(false);
        }

    };
    
    componentDidMount() {
        this.props.onRef(this);
    }

    render() {
        const { mode, visible, setVisible } = this.props;
        const { desDefValue } = this.state;
        return (
            <Modal
                visible={visible}
                onCancel={() => { setVisible(false) }}
                onOk={this.onFormSubmit}
                width={'70vw'}
                style={{ maxWidth: 800, minWidth: 600 }}
                title={mode === "ADD" ? "เพิ่มคลัง" : "ลดคลัง"}
                okText={mode === "ADD" ? "เพิ่ม" : "ลด"}
                cancelText={"ปิด"}
                keyboard={false}
                maskClosable={false}
                destroyOnClose={true}
            >
                <Form
                    ref={this.formRef}
                    layout={'vertical'}
                >
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item
                                hasFeedback
                                label="จำนวน"
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: "กรุณาใส่จำนวน"
                                    }
                                ]}>
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={24}>
                            <Form.Item
                                hasFeedback
                                label="คำอธิบาย"
                                name="description"
                                initialValue={desDefValue}
                                rules={[
                                    {
                                        message: "กรุณาใส่คำอธิบาย"
                                    }
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

export default StockHistoryFormModal;