import React, { Component } from "react";
import { Modal, Form, Input, Row, Col, Checkbox, Select } from "antd";
import { createItem, updateItem } from "../../services/item";
import { itemTypeChoices } from "../../utils/itemType";

const { Option } = Select;

class ItemFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.formRef = React.createRef();
  }

  async onFormSubmit() {
    const { data, setVisible, reloadAfterCreate, editing, reloadData } =
      this.props;
    let values;
    let passed = true;
    try {
      values = await this.formRef.current.validateFields();
    } catch (err) {
      values = err.values;
      passed = false;
    }

    if (passed) {
      if (!editing) {
        await createItem(values);
        reloadAfterCreate();
      } else {
        await updateItem(data["id"], values);
        reloadData();
      }
      setVisible(false);
    }
  }

  render() {
    const { editing, visible, setVisible, data } = this.props;

    return (
      <Modal
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={this.onFormSubmit}
        width={"70vw"}
        title={editing ? "แก้ไขรายการ" : "เพิ่มรายการ"}
        okText={editing ? "แก้ไข" : "เพิ่ม"}
        cancelText={"ปิด"}
        keyboard={false}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form ref={this.formRef} layout={"vertical"} initialValues={data}>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                hasFeedback
                label="ชื่อ"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "กรุณาใส่ชื่อ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                hasFeedback
                label="ราคาต่อหน่วย"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "กรุณาใส่ราคา",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                hasFeedback
                label="หน่วย"
                name="unit"
                rules={[
                  {
                    required: true,
                    message: "กรุณาใส่หน่วย",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                hasFeedback
                label="คำอธิบาย"
                name="description"
                rules={[]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            hasFeedback
            label="ประเภทสินค้า"
            name="item_type"
          >
            <Select
              showSearch
              style={{ width: "100%"}}
              placeholder="เลือกประเภทสินค้า"
              optionFilterProp="children"
            >
              {itemTypeChoices.map((itc) => (
                <Option key={itc.value} value={itc.value}>
                  {itc.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                name="is_recommended"
                valuePropName="checked"
                style={{ justifyContent: "left", display: 'flex' }}
              >
                <Checkbox> สินค้าแนะนำ</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={3}>
              <Form.Item
                name="is_ingredient"
                valuePropName="checked"
                style={{ justifyContent: "left", display: 'flex' }}
              >
                <Checkbox> วัตถุดิบ</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ItemFormModal;
