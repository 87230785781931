import axios from "axios";
import { convertSortDir } from "../utils";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const getStockChanges = async (q, filter_params, page, page_size, sort_by, sort_dir) => {

    let params = filter_params
    params['page'] = page
    params['page_size'] = page_size
    params['q'] = q
    params['sort_by'] = sort_by
    params['sort_dir'] = convertSortDir(sort_dir)

    const res = await axios.get(BACKEND_URL+'/stock_changes', {
        params,
        withCredentials: true
    });
    return res;
}

export const createStockChange = async (createObj) => {
    createObj  = Object.fromEntries(Object.entries(createObj).filter(([_, v]) => v !== undefined));
    const res = await axios.post(BACKEND_URL+'/stock_changes',
    createObj,
    {
        withCredentials: true,
        successMessage: "รายการได้ถูกสร้างแล้ว"
    });
    return res;
}

// export const updateStockChange = async (id, updateObj) => {
//     const res = await axios.put(BACKEND_URL+`/stock_changes/${id}`,
//     updateObj,
//     {
//         withCredentials: true,
//         successMessage: "StockChange ได้ถูกแก้ไขแล้ว"
//     });
//     return res;
// }
